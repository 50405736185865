import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { CreateLayout } from "src/components/spage";
import { useLazyAxios } from "src/hooks/useAxios";
import { IModel } from "./types";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { IOSSwitch } from "src/components/IOSSwitch";

interface IProps {}
export const ModulesForm = ({}: IProps) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    getValues,
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm<IModel>({
    defaultValues: {
      name: "",
      basePrice: 0,
      description: "",
      downPaymentPrice: 0,
      maxAge: 0,
      maxParticipants: 0,
      minAge: 0,
      minParticipants: 0,
      codeId: "",
      numberOfClasses: 0,
      classDuration: 0,
      seasonType: "FlatWaterSession",
      skillRequirements: [],
      published: true,
    },
    resolver: zodResolver(schema),
  });
  const isUpdate = !!id;
  const { isLoading: isCreating, execute: createModule } = useLazyAxios("api/Modules/Create");
  const { isLoading: isUpdating, execute: updateModule } = useLazyAxios("api/Modules/Update");

  const { isLoading: isGetting, execute: getModule } = useLazyAxios("api/Modules/Get");
  const { isLoading: isListingCodes, execute: listModules } =
    useLazyAxios("api/Options/ModuleCodes");

  const isLoading = isGetting || isCreating || isUpdating || isListingCodes;

  const [codes, setCodes] = useState<OptionsListModel[]>([]);

  useEffect(() => {
    if (id && !id.includes("-0000")) {
      (async () => {
        const response = await getModule({ id });
        if (response && response.response && response.raw.status === 200) {
          reset(response.response.data);
        } else {
          toast.error(response?.response?.message || "No record found", { duration: 5_000 });
        }
      })();
    }
    (async () => {
      const resp = await listModules();
      setCodes(resp?.response);
    })();
  }, [id]);
  const onSubmit = async (values: IModel) => {
    console.log(values);
    if (isLoading) return;
    const data: IModel = {
      ...values,
      id: id,
    };

    const response = await (isUpdate ? updateModule : createModule)(undefined, data);
    console.log(response);
    if (!!response?.response && response.raw.status === 200 && response.response.success) {
      toast.success("Saved successfully");
      navigate(-1);
    } else if (response?.raw.status === 400) {
      toast.error("Some values are missing but required.");
    } else {
      toast.error("Something went wrong. Please try again!");
    }
  };
  return (
    <CreateLayout
      isUpdate={isUpdate}
      title={(isUpdate ? "Update " : "Create ") + " Module"}
      pageTitle={"Modules"}
    >
      <form onSubmit={handleSubmit(onSubmit, console.log)}>
        <Card style={{ marginTop: 15 }}>
          <CardHeader title="Basic Info" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={!!errors.name?.message}
                  fullWidth
                  label="Name"
                  helperText={errors.name?.message}
                  {...register("name")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  sx={{ mt: 1.5 }}
                  control={
                    <Controller
                      control={control}
                      name="published"
                      render={({ field: { onChange, value } }) => (
                        <IOSSwitch
                          sx={{ ml: 1 }}
                          onChange={(e) => onChange(e.target.checked)}
                          checked={value}
                        />
                      )}
                    />
                  }
                  label={"Active: "}
                  labelPlacement="start"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="filled" error={!!errors.codeId?.message} fullWidth>
                  <InputLabel>Module Code</InputLabel>
                  <Controller
                    control={control}
                    name="codeId"
                    render={({ field }) => (
                      <Select {...field}>
                        <MenuItem value="">Please select</MenuItem>
                        {codes.map((s) => (
                          <MenuItem key={s.value} value={s.value}>
                            {s.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errors.codeId?.message && (
                    <FormHelperText>{errors.codeId?.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl variant="filled" error={!!errors.seasonType?.message} fullWidth>
                  <InputLabel>Season Type</InputLabel>
                  <Controller
                    control={control}
                    name="seasonType"
                    render={({ field }) => (
                      <Select {...field}>
                        <MenuItem value="DeepWaterSession">Deep Water Session</MenuItem>
                        <MenuItem value="FlatWaterSession">Flat Water Session</MenuItem>
                      </Select>
                    )}
                  />
                  {errors.seasonType?.message && (
                    <FormHelperText>{errors.seasonType?.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="filled" error={!!errors.skillRequirements?.message} fullWidth>
                  <InputLabel>Skill Requirements</InputLabel>
                  <Controller
                    control={control}
                    name="skillRequirements"
                    render={({ field }) => (
                      <Select multiple {...field}>
                        {codes.map((s) => (
                          <MenuItem key={s.value} value={s.value}>
                            {s.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errors.skillRequirements?.message && (
                    <FormHelperText>{errors.skillRequirements?.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  multiline
                  rows={1}
                  error={!!errors.description?.message}
                  fullWidth
                  label="Description"
                  helperText={errors.description?.message}
                  {...register("description")}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card style={{ marginTop: 15 }}>
          <CardHeader title="Advanced Info" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="number"
                  error={!!errors.minAge?.message}
                  fullWidth
                  label="Min Age"
                  helperText={errors.minAge?.message}
                  {...register("minAge", { valueAsNumber: true })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="number"
                  error={!!errors.maxAge?.message}
                  fullWidth
                  label="Max Age"
                  helperText={errors.maxAge?.message}
                  {...register("maxAge", { valueAsNumber: true })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="number"
                  error={!!errors.numberOfClasses?.message}
                  fullWidth
                  label="Number Of Classes"
                  helperText={errors.numberOfClasses?.message}
                  {...register("numberOfClasses", { valueAsNumber: true })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="number"
                  error={!!errors.classDuration?.message}
                  fullWidth
                  label="Class Duration"
                  helperText={errors.classDuration?.message}
                  {...register("classDuration", { valueAsNumber: true })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={!!errors.minParticipants?.message}
                  fullWidth
                  type="number"
                  label="Min Participants"
                  helperText={errors.minParticipants?.message}
                  {...register("minParticipants", { valueAsNumber: true })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="number"
                  error={!!errors.maxParticipants?.message}
                  fullWidth
                  label="Max Participants"
                  helperText={errors.maxParticipants?.message}
                  {...register("maxParticipants", { valueAsNumber: true })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="number"
                  error={!!errors.basePrice?.message}
                  fullWidth
                  label="Base Price"
                  helperText={errors.basePrice?.message}
                  {...register("basePrice", { valueAsNumber: true })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="number"
                  error={!!errors.downPaymentPrice?.message}
                  fullWidth
                  label="Down Payment Price"
                  helperText={errors.downPaymentPrice?.message}
                  {...register("downPaymentPrice", { valueAsNumber: true })}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button disabled={isLoading} type="submit" variant="contained" size="medium">
              Save
            </Button>
          </CardActions>
        </Card>
      </form>
    </CreateLayout>
  );
};

const required_message = "Required";
const greater_message = "Must be greater than 0";
const schema = z.object({
  id: z.string().optional().nullable(),
  name: z.string().min(1, required_message),
  description: z.string(),
  basePrice: z.number().min(1, greater_message),
  downPaymentPrice: z.number().min(1, greater_message),
  minAge: z.number().min(1, greater_message),
  maxAge: z.number().min(1, greater_message),
  minParticipants: z.number().min(1, greater_message),
  maxParticipants: z.number().min(1, greater_message),
  numberOfClasses: z.number().min(1, greater_message),
  classDuration: z.number().min(1, greater_message),
  skillRequirements: z.array(z.string().min(1, required_message)),
  codeId: z.string().min(1, required_message),
  seasonType: z.string().min(1, required_message),
  published: z.boolean(),
});
