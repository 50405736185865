import { useEffect } from 'react';
import nProgress from 'nprogress';
import { useNavigate, UNSAFE_NavigationContext, useLocation } from "react-router-dom";

export function useNProgress() {
  // const loc = useLocation();
  useEffect(() => {
    nProgress.start();

    setTimeout(() => {
      nProgress.done();
    }, 1000);
    // Router.events.on('routeChangeStart', nProgress.start);
    // Router.events.on('routeChangeError', nProgress.done);
    // Router.events.on('routeChangeComplete', nProgress.done);

    // return () => {
    //   Router.events.off('routeChangeStart', nProgress.start);
    //   Router.events.off('routeChangeError', nProgress.done);
    //   Router.events.off('routeChangeComplete', nProgress.done);
    // };
  }, []);
}
