import { SPage } from "src/components/spage";
import { ListTable } from "./table";
import { usePaginator } from "src/hooks/use-paginator";

interface IProps {}
export const QuestionnairesPage = ({}: IProps) => {
  const pagination = usePaginator("__questions__", "api/Question/List");
  return (
    <SPage
      title={"Questionnaires"}
      createOptions={{ enable: true }}
      searchOptions={{ enabled: true, ...pagination }}
    >
      <ListTable {...pagination} />
    </SPage>
  );
};
