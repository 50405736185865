import {
  AcademicCapIcon,
  ArchiveBoxXMarkIcon,
  BookOpenIcon,
  BriefcaseIcon,
  ClipboardDocumentListIcon,
  CogIcon,
  CubeIcon,
  CubeTransparentIcon,
  DocumentDuplicateIcon,
  PresentationChartBarIcon,
  QuestionMarkCircleIcon,
  ShoppingCartIcon,
  UserIcon
} from "@heroicons/react/24/solid";
import { SvgIcon } from "@mui/material";
import { IItems } from "./types";

export const admin: IItems[] = [
  {
    title: "Licensee (LN)",
    path: "/admin/licensee",
    icon: (
      <SvgIcon fontSize="small">
        <ClipboardDocumentListIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Fws Pools",
    path: "/admin/fws-pools",
    icon: (
      <SvgIcon fontSize="small">
        <CubeIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Tws Pools",
    path: "/admin/tws-pools",
    icon: (
      <SvgIcon fontSize="small">
        <CubeTransparentIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Trainers (SL)",
    path: "/admin/trainers",
    icon: (
      <SvgIcon fontSize="small">
        <DocumentDuplicateIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Customers (EZB)",
    path: "/admin/customers",
    icon: (
      <SvgIcon fontSize="small">
        <AcademicCapIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Module Codes",
    path: "/admin/module-codes",
    icon: (
      <SvgIcon fontSize="small">
        <BriefcaseIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Modules",
    path: "/admin/modules",
    icon: (
      <SvgIcon fontSize="small">
        <PresentationChartBarIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Module Course",
    path: "/admin/module-course",
    icon: (
      <SvgIcon fontSize="small">
        <BookOpenIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Orders",
    path: "/admin/orders",
    icon: (
      <SvgIcon fontSize="small">
        <ShoppingCartIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Cancellations",
    path: "/admin/cancel-request",
    icon: (
      <SvgIcon fontSize="small">
        <ArchiveBoxXMarkIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Questionnaire",
    path: "/admin/questions",
    icon: (
      <SvgIcon fontSize="small">
        <QuestionMarkCircleIcon />
      </SvgIcon>
    ),
  },
  {
    title: "My Profile",
    path: "/profile",
    icon: (
      <SvgIcon fontSize="small">
        <UserIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Settings",
    path: "/admin/settings",
    icon: (
      <SvgIcon fontSize="small">
        <CogIcon />
      </SvgIcon>
    ),
  },
];
