import { confirmAlert } from "react-confirm-alert";

interface IProps {
  onConfirm: () => Promise<void>;
  title?: string;
  message?: string;
}
export const confirmAlertUI = ({
  onConfirm,
  message = "Sind Sie sicher, dass Sie löschen möchten? Diese Aktion ist irreversibel.",
  title = "Achtung!",
}: IProps) => {
  //   const ui = <div></div>;
  confirmAlert({
    title: title,
    message: message,
    closeOnClickOutside: true,
    closeOnEscape: true,
    buttons: [
      {
        label: "No, cancel",
        // onClick: () => {},
      },
      {
        label: "Yes, Delete",
        style: { backgroundColor: "#F04438" },
        onClick: async () => {
          await onConfirm();
        },
      },
    ],
  });
};
