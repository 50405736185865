import { SPage } from "src/components/spage";
import { ListTable } from "./table";
import { usePaginator } from "src/hooks/use-paginator";

interface IProps {}
export const OrdersPage = ({}: IProps) => {
  const pagination = usePaginator("__orders__", "api/Orders/ListOrders");
  return (
    <SPage title={"Orders"} searchOptions={{ enabled: true, ...pagination }}>
      <ListTable {...pagination} />
    </SPage>
  );
};
