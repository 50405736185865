import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// import PropTypes from 'prop-types';
import { useSelector } from "react-redux";

export const AuthGuard = (props) => {
  const { children } = props;
  const router = useNavigate();
  const ignore = useRef(false);
  const [checked, setChecked] = useState(false);

  const state = useSelector((state: AppStore) => state);

  // Only do authentication check on component mount.
  // This flow allows you to manually redirect the user after sign-out, otherwise this will be
  // triggered and will automatically redirect to sign-in page.

  useEffect(
    () => {
      // if (navigation.state != 'idle') {
      //   return;
      // }

      // Prevent from calling twice in development mode with React.StrictMode enabled
      if (ignore.current) {
        return;
      }

      ignore.current = true;

      if (state.root.isRendered && !state.account.isAuthenticated) {
        console.log("Not authenticated, redirecting");
        router("/auth/login", { replace: true });
        // router
        //   .replace({
        //     pathname: '/auth/login',
        //     query: router.asPath !== '/' ? { continueUrl: router.asPath } : undefined
        //   })
        //   .catch(console.error);
      } else {
        setChecked(true);
      }
    },
    [] //[navigation.state]
  );

  if (!checked) {
    return null;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // authenticated / authorized.

  return children;
};

// AuthGuard.propTypes = {
//   children: PropTypes.node
// };
