import {
  AcademicCapIcon,
  ArchiveBoxIcon,
  CalendarDaysIcon,
  ClockIcon,
  CubeIcon,
  CubeTransparentIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/solid";
import UsersIcon from "@heroicons/react/24/solid/UsersIcon";
import { SvgIcon } from "@mui/material";
import { IItems } from "./types";

export const ln: IItems[] = [
  {
    title: "Fws Pools",
    path: "/ln/fws-pools",
    icon: (
      <SvgIcon fontSize="small">
        <CubeIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Tws Pools",
    path: "/ln/tws-pools",
    icon: (
      <SvgIcon fontSize="small">
        <CubeTransparentIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Trainers (SL)",
    path: "/ln/trainers",
    icon: (
      <SvgIcon fontSize="small">
        <AcademicCapIcon />
      </SvgIcon>
    ),
  },
  {
    title: "My Orders",
    path: "/ln/my-orders",
    icon: (
      <SvgIcon fontSize="small">
        <ShoppingCartIcon />
      </SvgIcon>
    ),
  },
  {
    title: "My bookings",
    path: "/ln/my-bookings",
    icon: (
      <SvgIcon fontSize="small">
        <ArchiveBoxIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Schedule Modules",
    path: "/ln/schedule-module",
    icon: (
      <SvgIcon fontSize="small">
        <ClockIcon />
      </SvgIcon>
    ),
  },
  {
    title: "UE Calendar",
    path: "/ln/ue-caneldar",
    icon: (
      <SvgIcon fontSize="small">
        <CalendarDaysIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Profiles",
    path: "/ln/profiles",
    icon: (
      <SvgIcon fontSize="small">
        <UsersIcon />
      </SvgIcon>
    ),
  },
];
