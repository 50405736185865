import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { GridItem } from "src/components/grid-item";
import { MuiPhone } from "src/components/phone-input";
import { CreateLayout } from "src/components/spage";
import { useLazyAxios } from "src/hooks/useAxios";
import { FwsRepeater } from "./fws-repeater";
import { FwsSchema, TwsSchema } from "./schemas";
import { IPoolModel, IProps } from "./types";

export const PoolsForm = ({ type, from }: IProps) => {
  const navigate = useNavigate();

  const { id } = useParams();
  const isUpdate = !!id;

  const {
    getValues,
    handleSubmit,
    register,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm<IPoolModel>({
    defaultValues: {
      name: "",
      phone: "",
      address: "",
      contactPersonName: "",
      email: "",
      licenseQuota: 0,
      poolType: type,
      assignedPools: [],
    },
    resolver: zodResolver(type === "TWS" ? TwsSchema : FwsSchema),
    mode: "all",
  });

  const { isLoading: isCreatingFws, execute: createFws } = useLazyAxios("api/FwsPools/Create");
  const { isLoading: isUpdatingFws, execute: updateFws } = useLazyAxios("api/FwsPools/Update");

  const { isLoading: isCreatingTws, execute: createTws } = useLazyAxios("api/TwsPools/Create");
  const { isLoading: isUpdatingTws, execute: updateTws } = useLazyAxios("api/TwsPools/Update");

  const { isLoading: loadingFwsGet, execute: FwsGet } = useLazyAxios("api/FwsPools/Get");
  const { isLoading: loadingTwsGet, execute: TwsGet } = useLazyAxios("api/TwsPools/Get");

  const {
    isLoading: loadingLNs,
    response,
    execute: ListLNs,
  } = useLazyAxios("api/Options/Licensees");

  const isLoading =
    isCreatingFws ||
    isUpdatingFws ||
    isCreatingTws ||
    isUpdatingTws ||
    loadingTwsGet ||
    loadingFwsGet ||
    loadingLNs;

  useEffect(() => {
    if (id && !id.includes("-0000")) {
      (async () => {
        const response = await (type === "FWS" ? FwsGet : TwsGet)({ id });
        if (response && response.response && response.raw.status === 200) {
          reset(response.response.data);
        } else {
          toast.error(response?.response?.message || "No record found", { duration: 5_000 });
        }
      })();
    }
    (async () => {
      await ListLNs();
    })();
  }, [id]);

  const onSubmit = async (values: IPoolModel) => {
    if (isLoading) return;
    const data: IPoolModel = {
      ...values,
      poolType: type,
      id: id,
    };

    const response = await (type === "FWS"
      ? isUpdate
        ? updateFws
        : createFws
      : isUpdate
      ? updateTws
      : createTws)(undefined, data);
    console.log(response);
    if (!!response?.response && response.raw.status === 200 && response.response.success) {
      toast.success("Saved successfully");
      navigate(-1);
    } else if (response?.raw.status === 400) {
      toast.error("Some values are missing but required.");
    } else {
      toast.error("Something went wrong. Please try again!");
    }
  };

  const SubmitAction = (
    <CardActions sx={{ justifyContent: "flex-end" }}>
      <Button disabled={isLoading} type="submit" variant="contained" size="medium">
        Save
      </Button>
    </CardActions>
  );

  return (
    <CreateLayout
      isUpdate={isUpdate}
      title={(isUpdate ? "Update " : "Create ") + type + " Pool"}
      pageTitle={type + " Pool"}
    >
      <form onSubmit={handleSubmit(onSubmit, console.log)}>
        <Card style={{ marginTop: 15 }}>
          <CardHeader title="Basic Info" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <GridItem>
                <TextField
                  fullWidth
                  error={!!errors.name?.message}
                  helperText={errors.name?.message}
                  label="Surname"
                  {...register("name")}
                />
              </GridItem>
              <GridItem>
                <TextField
                  fullWidth
                  error={!!errors.contactPersonName?.message}
                  helperText={errors.contactPersonName?.message}
                  label="Contact person name"
                  {...register("contactPersonName")}
                />
              </GridItem>
              <GridItem>
                <TextField
                  fullWidth
                  helperText={errors.licenseQuota?.message}
                  error={!!errors.licenseQuota?.message}
                  type="number"
                  label="License Quota"
                  {...register("licenseQuota", { valueAsNumber: true })}
                />
              </GridItem>
            </Grid>
          </CardContent>
        </Card>
        <Card style={{ marginTop: 15 }}>
          <CardHeader title="Contact Info" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <GridItem>
                <TextField
                  fullWidth
                  error={!!errors.email?.message}
                  helperText={errors.email?.message}
                  label="Email"
                  type="email"
                  {...register("email")}
                />
              </GridItem>
              <GridItem>
                <MuiPhone
                  fullWidth
                  error={!!errors.phone?.message}
                  helperText={errors.phone?.message}
                  value={getValues("phone")}
                  onChange={(e) => setValue("phone", e)}
                />
              </GridItem>
            </Grid>
          </CardContent>
          {type !== "FWS" && (
            <>
              <Divider />
              {SubmitAction}
            </>
          )}
        </Card>
        {type === "FWS" && (
          <FwsRepeater
            control={control}
            errors={errors}
            action={SubmitAction}
            register={register}
            list={response || []}
          />
        )}
      </form>
    </CreateLayout>
  );
};
