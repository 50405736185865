import { Routes, Route, Outlet, useNavigate, useLocation } from "react-router-dom";
import { LoginPage } from "./auth/login";
import { AuthLayout } from "./layouts/auth/AuthLayout";
import { DashboardLayout } from "./layouts/dashboard/layout";
import { ErrorPage } from "./pages/404";
import { CustomersPage } from "./pages/customers";
import { CustomerForm } from "./pages/customers/customers-form";
import { MainPage } from "./pages/dashboard";
import { ProfilePage } from "./pages/profile";
import { SettingsPage } from "./pages/settings";
import { PoolsPage } from "./pages/pools";
import { TrainersPage } from "./pages/trainers";
import { LicenseePage } from "./pages/licensee";
import { ModuleCodesPage } from "./pages/module-codes";
import { ModulesPage } from "./pages/modules";
import { ModuleCoursePage } from "./pages/module-course";
import { OrdersPage } from "./pages/orders";
import { CancellationsPage } from "./pages/cancellations";
import { QuestionnairesPage } from "./pages/questionnaire";
import { MyOrdersPage } from "./pages/myorders";
import { MyBookingsPage } from "./pages/mybookings";
import { ScheduleModulesPage } from "./pages/schedule-modules";
import { UeCalendarPage } from "./pages/uecalendar";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { PoolsForm } from "./pages/pools/pools-form";
import { CourseModuleForm } from "./pages/module-course/module-course-form";
import { ModuleCodesForm } from "./pages/module-codes/module-codes-form";
import { ModulesForm } from "./pages/modules/modules-form";
import { QuestionForm } from "./pages/questionnaire/questionnaire-form";
import { ForgotPasswordPage } from "./auth/forgotpassword";
import { AdminsPage } from "./pages/admins";
import { ResetPassword } from "./auth/resetPassword";

const LGRoutes = ({ role }: { role: Roles }) => (
  <Route path={role === "Admin" ? "admin" : "lg"} element={<RoleSecurity expected={role} />}>
    <Route path="fws-pools" element={<Outlet />}>
      <Route index element={<PoolsPage from="LG" type="FWS" />} />
      <Route path="create" element={<PoolsForm from="LG" type="FWS" />} />
      <Route path="edit/:id" element={<PoolsForm from="LG" type="FWS" />} />
    </Route>
    <Route path="tws-pools" element={<Outlet />}>
      <Route index element={<PoolsPage from="LG" type="TWS" />} />
      {/* <Route path="create" element={<PoolsForm from="LG" type="TWS" />} />
            <Route path="edit/:id" element={<PoolsForm from="LG" type="TWS" />} /> */}
    </Route>
    <Route path="trainers" element={<Outlet />}>
      <Route index element={<TrainersPage from="LG" />} />
      {/* <Route path="create" element={<CustomerForm currentPage="SL" />} />
            <Route path="edit/:id" element={<CustomerForm currentPage="SL" />} /> */}
    </Route>
    <Route path="customers" element={<Outlet />}>
      <Route index element={<CustomersPage from="LG" />} />
      <Route path="create" element={<CustomerForm currentPage="EZB" />} />
      <Route path="edit/:id" element={<CustomerForm currentPage="EZB" />} />
    </Route>
    <Route path="licensee" element={<Outlet />}>
      <Route index element={<LicenseePage from="LG" />} />
      <Route path="create" element={<CustomerForm currentPage="LN" />} />
      <Route path="edit/:id" element={<CustomerForm currentPage="LN" />} />
    </Route>
    <Route path="module-codes" element={<Outlet />}>
      <Route index element={<ModuleCodesPage />} />
      <Route path="create" element={<ModuleCodesForm />} />
      <Route path="edit/:id" element={<ModuleCodesForm />} />
    </Route>
    <Route path="modules" element={<Outlet />}>
      <Route index element={<ModulesPage />} />
      <Route path="create" element={<ModulesForm />} />
      <Route path="edit/:id" element={<ModulesForm />} />
    </Route>
    <Route path="module-course" element={<Outlet />}>
      <Route index element={<ModuleCoursePage />} />
      <Route path="create" element={<CourseModuleForm />} />
      <Route path="edit/:id" element={<CourseModuleForm />} />
    </Route>
    <Route path="questions" element={<Outlet />}>
      <Route index element={<QuestionnairesPage />} />
      <Route path="create" element={<QuestionForm />} />
      <Route path="edit/:id" element={<QuestionForm />} />
    </Route>
    <Route path="orders" element={<OrdersPage />} />
    <Route path="cancel-request" element={<CancellationsPage />} />
    <Route path="settings" element={<SettingsPage />} />
    {role === "LG" && (
      <Route path="admin-profile" element={<Outlet />}>
        <Route index element={<AdminsPage from={role} />} />
        <Route path="create" element={<CustomerForm currentPage="Admin" />} />
        <Route path="edit/:id" element={<CustomerForm currentPage="Admin" />} />
      </Route>
    )}
  </Route>
);
export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<DashboardLayout />}>
        <Route index element={<MainPage />} />
        {LGRoutes({ role: "Admin" })}
        {LGRoutes({ role: "LG" })}

        <Route path="ln" element={<RoleSecurity expected="LN" />}>
          <Route path="fws-pools" element={<Outlet />}>
            <Route index element={<PoolsPage from="LN" type="FWS" />} />
            <Route path="create" element={<PoolsForm from="LN" type="FWS" />} />
            <Route path="edit/:id" element={<PoolsForm from="LN" type="FWS" />} />
          </Route>
          <Route path="tws-pools" element={<Outlet />}>
            <Route index element={<PoolsPage from="LN" type="TWS" />} />
            <Route path="create" element={<PoolsForm from="LN" type="TWS" />} />
            <Route path="edit/:id" element={<PoolsForm from="LN" type="TWS" />} />
          </Route>
          <Route path="trainers" element={<Outlet />}>
            <Route index element={<TrainersPage from="LN" />} />
            <Route path="create" element={<CustomerForm currentPage="SL" />} />
            <Route path="edit/:id" element={<CustomerForm currentPage="SL" />} />
          </Route>
          <Route path="my-orders" element={<MyOrdersPage />} />
          <Route path="my-bookings" element={<MyBookingsPage />} />
          <Route path="schedule-module" element={<ScheduleModulesPage />} />
          <Route path="ue-caneldar" element={<UeCalendarPage />} />
          <Route path="profiles" element={<ProfilePage />} />
        </Route>

        <Route path="settings" element={<SettingsPage />} />
        <Route path="profile" element={<ProfilePage />} />
      </Route>

      <Route path="/auth" element={<AuthLayout />}>
        <Route path="login" element={<LoginPage />} />
        <Route path="forgotpassword" element={<ForgotPasswordPage />} />
        <Route path="reset-password" element={<ResetPassword />} />
      </Route>

      <Route path="access-denied" element={<ErrorPage error={401} />} />
      <Route path="error" element={<ErrorPage error={500} />} />
      <Route path="*" element={<ErrorPage error={404} />} />
    </Routes>
  );
};

const RoleSecurity = ({ expected }: { expected: Roles | Roles[] }) => {
  const { isAuthenticated, role } = useSelector((state: AppStore) => state.account);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    if (
      !isAuthenticated ||
      (typeof expected === "string" ? role !== expected : !expected.includes(role))
    ) {
      navigate("/access-denied", { replace: true });
    }
  }, [navigate, pathname]);
  return <Outlet />;
};
