import { Box, Button, Link, Stack, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { Link as NLink, useNavigate } from "react-router-dom";
import { useLazyAxios } from "src/hooks/useAxios";
import * as z from "zod";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setIsAuthenticated, setProfile } from "src/store/account-slice";
import { useEffect } from "react";
import { setIsRendered } from "src/store/root-slice";
import { zodResolver } from "@hookform/resolvers/zod";

export const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isError, isLoading, execute } = useLazyAxios("login");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "lg@sp.com",
      password: "Admin@123",
    },
    resolver: zodResolver(
      z.object({
        username: z.string().email("Must be a valid email").max(255).min(1, "Email is required"),
        password: z.string().max(255).min(1, "Password is required"),
      })
    ),
  });

  const onSubmit = async (values: any) => {
    const result = await execute(undefined, values);
    const response = result?.response;
    if (response && "accessToken" in response) {
      localStorage.setItem("authenticated", "true");
      localStorage.setItem("token", JSON.stringify(response));
      dispatch(setIsAuthenticated(true));
      await renderProfile();
      navigate("/", { replace: true });
    } else {
      toast.error("Invalid username or password.");
    }
  };

  const { execute: getMyProfile } = useLazyAxios("api/Account/MyProfile");

  const renderProfile = async () => {
    const response = await getMyProfile();
    console.log(response);
    if (response?.response.data) dispatch(setProfile(response?.response.data));

    dispatch(setIsRendered(true));
  };
  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        flex: "1 1 auto",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          maxWidth: 550,
          px: 3,
          py: "100px",
          width: "100%",
        }}
      >
        <div>
          <Stack spacing={1} sx={{ mb: 3 }}>
            <Typography variant="h4">Login</Typography>
            <Link
              onClick={() => {
                setValue("username", "admin@sp.com");
              }}
              style={{ cursor: "pointer" }}
              underline="hover"
              variant="subtitle2"
            >
              admin@sp.com
            </Link>
            <Link
              onClick={() => {
                setValue("username", "lg@sp.com");
              }}
              style={{ cursor: "pointer" }}
              underline="hover"
              variant="subtitle2"
            >
              lg@sp.com
            </Link>
            <Link
              onClick={() => {
                setValue("username", "ln@sp.com");
              }}
              style={{ cursor: "pointer" }}
              underline="hover"
              variant="subtitle2"
            >
              ln@sp.com
            </Link>
            {/* <Typography color="text.secondary" variant="body2">
              Don&apos;t have an account? &nbsp;
              <Link component={NLink} to="/auth/register" underline="hover" variant="subtitle2">
                Register
              </Link>
            </Typography> */}
          </Stack>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <TextField
                error={!!errors.username?.message}
                fullWidth
                helperText={errors.username?.message}
                label="Email Address"
                {...register("username")}
                type="email"
              />
              <TextField
                error={!!errors.password?.message}
                fullWidth
                helperText={errors.password?.message}
                label="Password"
                type="password"
                {...register("password")}
              />
              <Typography textAlign={"end"} color="text.secondary" variant="body2">
                <Link
                  component={NLink}
                  to="/auth/forgotpassword"
                  underline="hover"
                  variant="subtitle2"
                >
                  Forgot password?
                </Link>
              </Typography>
            </Stack>
            {/* {formik.errors.submit && (
              <Typography
                color="error"
                sx={{ mt: 3 }}
                variant="body2"
              >
                {formik.errors.submit}
              </Typography>
            )} */}
            <Button
              disabled={isLoading}
              fullWidth
              size="large"
              sx={{ mt: 3 }}
              type="submit"
              variant="contained"
            >
              Login
            </Button>
          </form>
        </div>
      </Box>
    </Box>
  );
};
