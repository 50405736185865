import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useLazyAxios } from "src/hooks/useAxios";
import { Scrollbar } from "../../components/scrollbar";
import { IModel } from "./types";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import { confirmAlertUI } from "src/components/alertUI";

export const ListTable: React.FC<TableProps<IModel>> = ({
  setOnAction,
  count,
  items,
  onPageChange,
  onRowsPerPageChange,
  page,
  rowsPerPage,
  setDirection,
  setSortColumn,
  sortColumn,
  sortDirection,
}) => {
  const [rows, setRows] = useState(items);
  useMemo(() => {
    setRows(items);
  }, [items]);
  return (
    <Card>
      <Scrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                {heads.map((s) => (
                  <TableCell
                    sortDirection={
                      sortColumn === s.id ? (sortDirection === "Asc" ? "asc" : "desc") : false
                    }
                    key={s.id}
                  >
                    {s.sortable ? (
                      <TableSortLabel
                        onClick={(e) => {
                          const isAsc = sortColumn === s.id && sortDirection === "Asc";
                          setDirection(isAsc ? "Desc" : "Asc");
                          setSortColumn(s.id);
                        }}
                        active={s.id === sortColumn}
                        direction={sortDirection === "Asc" ? "asc" : "desc"}
                      >
                        {s.title}
                      </TableSortLabel>
                    ) : (
                      s.title
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <Row
                  key={row.id}
                  i={i}
                  onRemove={() => {
                    const lr = [...rows];
                    const index = lr.indexOf(row);
                    lr.splice(index, 1);
                    setRows(lr);
                  }}
                  page={page}
                  row={row}
                  rowsPerPage={rowsPerPage}
                />
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50]}
      />
    </Card>
  );
};
const Row = ({
  row,
  i,
  page,
  onRemove,
  rowsPerPage,
}: {
  row: IModel;
  i: number;
  rowsPerPage: number;
  page: number;
  onRemove: () => void;
}) => {
  const navigate = useNavigate();
  const { execute, isLoading } = useLazyAxios("api/Courses/Delete");
  return (
    <TableRow hover>
      <TableCell>{i + 1 + rowsPerPage * page}</TableCell>
      <TableCell>{row.name}</TableCell>
      {/* <TableCell>{}</TableCell> */}
      <TableCell>{row.basePrice}</TableCell>
      <TableCell>{row.downPaymentPrice}</TableCell>
      <TableCell sx={{ display: "flex" }}>
        {isLoading ? (
          <CircularProgress size={20} />
        ) : (
          <>
            <IconButton color="primary" onClick={() => navigate("edit/" + row.id)}>
              <SvgIcon fontSize="small">
                <PencilSquareIcon />
              </SvgIcon>
            </IconButton>
            <IconButton
              color="error"
              onClick={() =>
                confirmAlertUI({
                  onConfirm: async () => {
                    await execute({ id: row.id! });
                    onRemove();
                  },
                })
              }
            >
              <SvgIcon fontSize="small">
                <TrashIcon />
              </SvgIcon>
            </IconButton>
          </>
        )}
      </TableCell>
    </TableRow>
  );
};
const heads = [
  {
    id: "id",
    title: "#",
    sortable: false,
  },
  {
    id: "name",
    title: "Name",
    sortable: true,
  },
  // {
  //   id: "description",
  //   title: "Description",
  //   sortable: true,
  // },
  {
    id: "basePrice",
    title: "Base Price",
    sortable: true,
  },
  {
    id: "downPayment",
    title: "Down payment",
    sortable: true,
  },
  {
    id: "action",
    title: "Action",
    sortable: true,
  },
];
