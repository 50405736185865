import ChartBarIcon from "@heroicons/react/24/solid/ChartBarIcon";
import { SvgIcon } from "@mui/material";
import { lg } from "./lg";
import { IItems } from "./types";
import { ln } from "./ln";
import { admin } from "./admin";

export const items = (role: Roles): IItems[] => [
  {
    title: "Dashboard",
    path: "/",
    icon: (
      <SvgIcon fontSize="small">
        <ChartBarIcon />
      </SvgIcon>
    ),
  },
  ...(role === "LG" ? lg : role === "LN" ? ln : role === "Admin" ? admin : []),
];
