import { SPage } from "src/components/spage";
import { URLs } from "src/hooks/types";
import { usePaginator } from "src/hooks/use-paginator";
import { CustomersTable } from "../customers/customers-table";

interface IProps {
  from: Roles;
}
export const AdminsPage = ({ from }: IProps) => {
  const { ...props } = usePaginator(`__admins__`, "api/Admin/List");
  return (
    <SPage
      createOptions={{ enable: true }}
      searchOptions={{
        enabled: true,
        ...props,
      }}
      title={"Admins"}
      pageTitle="Admins"
    >
      <CustomersTable {...props} currentPage="Admin" from={from} />
    </SPage>
  );
};
