import { FormControlLabel } from "@mui/material";
import { useState } from "react";
import { IOSSwitch } from "./IOSSwitch";
import { URLs } from "src/hooks/types";
import { useLazyAxios } from "src/hooks/useAxios";

interface IProps {
  id?: string;
  defaultValue: boolean;
  url: URLs;
  readonly?: boolean;
  label?: string;
}
export const StatusSwitch = ({ defaultValue, id, url, readonly, label }: IProps) => {
  const [checked, setChecked] = useState(defaultValue);
  const { isLoading, execute } = useLazyAxios(url);
  const onChange = async (check: boolean) => {
    setChecked(check);
    await execute(undefined, {
      id: id,
      status: check,
    });
  };
  return (
    <FormControlLabel
      disabled={readonly}
      sx={{ ml: 0 }}
      control={
        <IOSSwitch
          readOnly={readonly}
          sx={{ ml: 1 }}
          checked={checked}
          onChange={async (e) => await onChange(e.target.checked)}
        />
      }
      label={label}
      labelPlacement="start"
    />
  );
};
