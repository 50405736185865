import { z } from "zod";

const required_error = "Required";
const greater_error = "Must be greater than 0";
export const FwsSchema = z.object({
  name: z.string().min(1, required_error),
  contactPersonName: z.string().min(1, required_error),
  email: z.string().email(required_error),
  phone: z.string(),
  address: z.string(),
  licenseQuota: z.number().min(1, greater_error),
  poolType: z.string().optional().nullable(),
  assignedPools: z.array(
    z.object({
      id: z.string({}).optional().nullable(),
      licenseeId: z.string().min(1, required_error), //.min(1, required_error),
      licenseQuota: z.number().min(1, greater_error),
    })
  ),
});
export const TwsSchema = z.object({
  name: z.string().min(1, required_error),
  contactPersonName: z.string().min(1, required_error),
  email: z.string().email(required_error),
  phone: z.string(),
  address: z.string(),
  licenseQuota: z.number().min(1, greater_error),
  poolType: z.string().optional().nullable(),
});
