import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { CreateLayout } from "src/components/spage";
import { useLazyAxios } from "src/hooks/useAxios";
import { PoolType } from "../pools/types";
import { IModel } from "./types";
import { IOSSwitch } from "src/components/IOSSwitch";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

interface IProps {}
export const QuestionForm = ({}: IProps) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    getValues,
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm<IModel>({
    defaultValues: {
      title: "",
      published: true,
    },
    resolver: zodResolver(schema),
  });
  const isUpdate = !!id;
  const { isLoading: isCreating, execute: createQuestion } = useLazyAxios("api/Question/Create");
  const { isLoading: isUpdating, execute: updateQuestion } = useLazyAxios("api/Question/Update");

  const { isLoading: isGetting, execute: getQuestion } = useLazyAxios("api/Question/Get");

  const isLoading = isCreating || isUpdating || isGetting;

  useEffect(() => {
    if (id && !id.includes("-0000")) {
      (async () => {
        const response = await getQuestion({ id });
        if (response && response.response && response.raw.status === 200) {
          reset(response.response.data);
        } else {
          toast.error(response?.response?.message || "No record found", { duration: 5_000 });
        }
      })();
    }
  }, [id]);
  const onSubmit = async (values: IModel) => {
    console.log(values);
    if (isLoading) return;
    const data: IModel = {
      ...values,
      id: id!!,
    };

    const response = await (isUpdate ? updateQuestion : createQuestion)(undefined, data);
    console.log(response);
    if (!!response?.response && response.raw.status === 200 && response.response.success) {
      toast.success("Saved successfully");
      navigate(-1);
    } else if (response?.raw.status === 200) {
      toast.error("Some values are missing but required.");
    } else {
      toast.error("Something went wrong. Please try again!");
    }
  };
  return (
    <CreateLayout
      isUpdate={isUpdate}
      title={(isUpdate ? "Update " : "Create ") + " Question"}
      pageTitle={"Questions"}
    >
      <form onSubmit={handleSubmit(onSubmit, console.log)}>
        <Card style={{ marginTop: 15 }}>
          <CardHeader title="Basic Info" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={!!errors.title?.message}
                  fullWidth
                  label="Title"
                  helperText={errors.title?.message}
                  {...register("title")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  sx={{ mt: 1.5 }}
                  control={
                    <Controller
                      control={control}
                      name="published"
                      render={({ field: { onChange, value } }) => (
                        <IOSSwitch
                          sx={{ ml: 1 }}
                          onChange={(e) => onChange(e.target.checked)}
                          checked={value}
                        />
                      )}
                    />
                  }
                  label={"Active: "}
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button disabled={isLoading} type="submit" variant="contained" size="medium">
              Save
            </Button>
          </CardActions>
        </Card>
      </form>
    </CreateLayout>
  );
};
const schema = z.object({
  title: z.string().min(1, "Required"),
  published: z.boolean(),
});
