import { useCallback, useEffect, useState } from 'react';
import { Theme, styled, useTheme } from '@mui/material/styles';
import { SideNav } from './side-nav';
import { TopNav } from './top-nav';
import { Outlet, useLocation } from 'react-router-dom';
import { withAuthGuard } from '../../hocs/with-auth-guard';
import { useMediaQuery } from '@mui/material';


export const DashboardLayout = withAuthGuard(() => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const isUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const stateFromStorage = localStorage.getItem("side_nav");
  const [openNav, setOpenNav] = useState<boolean>(isUpLg && (!stateFromStorage || stateFromStorage === 'true'));

  const handlePathnameChange = useCallback(
    () => {
      if (openNav) {
        setOpenNav(false);
      }
    },
    [openNav]
  );

  useEffect(
    () => {
      if (!isUpLg)
        handlePathnameChange();
    },
    [pathname]
  );

  return (
    <>
      <TopNav onNavOpen={() => {
        setOpenNav(!openNav);
        localStorage.setItem("side_nav", `${(!openNav)}`)
      }} isOpen={openNav} />
      <SideNav
        onClose={() => {
          setOpenNav(false);
          localStorage.setItem("side_nav", `${(!openNav)}`)
        }}
        open={openNav}
      />
      <LayoutRoot isOpen={openNav}>
        <LayoutContainer>
          <Outlet />
          {/* {children} */}
        </LayoutContainer>
      </LayoutRoot>
    </>
  );
});


const SIDE_NAV_WIDTH = 280;

const LayoutRoot = styled('div')<{ isOpen: boolean }>(({ theme, isOpen }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: isOpen ? SIDE_NAV_WIDTH : 0
  }
}));

const LayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%'
});