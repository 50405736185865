import { useDispatch } from "react-redux";
import { useLazyAxios } from "./hooks/useAxios";
import { useEffect } from "react";
import { setProfile } from "./store/account-slice";
import { setIsRendered } from "./store/root-slice";
import { CirclesWithBar } from "react-loader-spinner";

export const SplashScreen = () => {
  const dispatch = useDispatch();
  const { execute } = useLazyAxios("api/Account/MyProfile");

  useEffect(() => {
    (async () => {
      const response = await execute();
      if (response?.response.data) dispatch(setProfile(response?.response.data));

      dispatch(setIsRendered(true));
    })();
  }, []);

  return (
    <>
      <div className="splash-screen">
        <img
          src="/logo.svg"
          alt="Wassermenschen Logo"
          style={{
            width: "10%",
            height: "auto",
          }}
        />
        <div className="loader-container">
          <CirclesWithBar
            height="100"
            width="100"
            color="#009ee1"
            outerCircleColor="#009ee1"
            innerCircleColor="#009ee1"
            barColor="#009ee1"
            ariaLabel="circles-with-bar-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
        <p className="loading-text">Please wait...</p>
      </div>
    </>
  );
};
