import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { Title } from "src/components/title";
import zod from "zod";
import toast from "react-hot-toast";
import { useLazyAxios } from "src/hooks/useAxios";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
const translations = {
  en: {
    strong: "Choose Strong password",
    updated: "Your password is updated.",
    reset: "Reset Password",
    password: "Password",
    email: "Email",
    code: "Code",
    change: "Change Password",
  },
  de: {
    strong: "Wählen Sie ein starkes Passwort",
    updated: "Ihr Passwort wurde aktualisiert.",
    reset: "Passwort zurücksetzen",
    password: "Passwort",
    email: "E-Mail",
    code: "Code",
    change: "Passwort ändern",
  },
};

export const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const { language } = useParams();
  const lang = language || "de";
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: searchParams.get("email") || "",
      resetCode: searchParams.get("resetCode") || "",
      newPassword: "",
    },
    resolver: zodResolver(
      zod.object({
        email: zod.string().min(1, "Required"),
        resetCode: zod.string().min(1, "Required"),
        newPassword: zod
          .string({
            required_error: "Required",
          })
          .min(6, translations[lang].strong),
      })
    ),
  });

  useEffect(() => {
    if (!searchParams.has("email") || !searchParams.get("email")) {
      toast.error("Email is missing.", { duration: 50_000 });
    }
    if (!searchParams.has("resetCode") || !searchParams.get("resetCode")) {
      toast.error("Token is missing.", { duration: 50_000 });
    }
  }, [searchParams]);

  const { isLoading, execute } = useLazyAxios("resetPassword");

  const onSubmit = async (v) => {
    const response = await execute(undefined, {
      ...v,
      email: searchParams.get("email") || "",
      resetCode: searchParams.get("resetCode") || "",
    });
    if (response?.raw.status == 200) {
      toast.success(translations[lang].updated);
    } else if (response?.raw.status == 400) {
      const id = toast.error("Code expired.");
    }
  };
  return (
    <>
      <Title title={translations[lang].reset} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <div>
              <Typography variant="h4">{translations[lang].reset}</Typography>
            </div>
            <div>
              <Grid container spacing={3}>
                <Grid xs={12} md={12} lg={12}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Card>
                      <CardHeader
                        subheader={translations[lang].reset}
                        title={translations[lang].password}
                      />
                      <Divider />
                      <CardContent>
                        <Stack spacing={3} sx={{ maxWidth: 400 }}>
                          <TextField
                            error={!!errors.email?.message}
                            fullWidth
                            label={translations[lang].email}
                            type="email"
                            helperText={errors.email?.message}
                            {...register("email", { disabled: true })}
                          />
                          <TextField
                            error={!!errors.resetCode?.message}
                            fullWidth
                            label={translations[lang].code}
                            type="text"
                            helperText={errors.resetCode?.message}
                            {...register("resetCode", { disabled: true })}
                          />
                          <TextField
                            error={!!errors.newPassword?.message!!}
                            fullWidth
                            label={translations[lang].password}
                            helperText={errors.newPassword?.message}
                            {...register("newPassword")}
                            type="password"
                          />
                        </Stack>
                      </CardContent>
                      <Divider />
                      <CardActions sx={{ justifyContent: "flex-end" }}>
                        <Button disabled={isLoading} type="submit" variant="contained">
                          {translations[lang].change}
                        </Button>
                      </CardActions>
                    </Card>
                  </form>
                </Grid>
              </Grid>
            </div>
          </Stack>
        </Container>
      </Box>
    </>
  );
};
