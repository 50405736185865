import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import npProgress from "nprogress";
import { BodyTypes, ParamTypes, ReturnTypes, URLs, getDefaultParams, methods } from "./types";
import toast from "react-hot-toast";

const baseUrl = process.env["REACT_APP_BASE_URL"];

export const useLazyAxios = <URL extends URLs = URLs, RT = ReturnTypes[URL]>(url: URL) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [response, setResponse] = useState<RT>();

  const execute = async (params?: ParamTypes[URL], body?: BodyTypes[URL]) => {
    const loaderId = toast.loading("Please wait...", { id: "loader" });
    npProgress.start();
    setIsLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("token") || "{}") as TokenResponse;
      let lang = navigator.language;
      lang = lang.includes("en") ? "en-US" : "de-DE";
      const response = await axios<RT>({
        baseURL: baseUrl,
        url: url,
        method: methods[url],
        params: params || getDefaultParams(url),
        data: body,
        headers: {
          Authorization: "Bearer " + token.accessToken,
          App: "Web",
          "Accept-Language": lang,
        },
      });
      setResponse(response.data);
      setIsLoading(false);
      npProgress.done();
      toast.remove(loaderId);
      const raw = response;
      return { raw, response: response.data };
    } catch (error: unknown) {
      toast.remove(loaderId);
      setIsLoading(false);
      setIsError(true);
      npProgress.done();
      if (error instanceof AxiosError && error.response) {
        const statusCode = error.response?.status;
        if (statusCode === 400) {
          toast.error("Submitted data contains error, or some fields are missing.");
        } else if (statusCode === 401 || statusCode === 403) {
          navigate("/auth/login", { replace: true });
        } else if (error.response?.status >= 500) {
          navigate("/error", { replace: true });
        }
        return { raw: error.response, response: error.response.data };
      }
    }
  };

  return { response, isLoading, isError, execute };
};
