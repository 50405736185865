import { useState, useEffect, useMemo } from "react";
import { useLazyAxios } from "./useAxios";
import { URLs } from "./types";

export const usePaginator = (id: string, url: URLs, _rowsPerPage: number = 10) => {
  // Initialize state values with default values or retrieve from localStorage
  const [text, setText] = useState(localStorage.getItem(id + "query") || "");
  const [page, setPage] = useState(Number(localStorage.getItem(id + "page")) || 0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(localStorage.getItem(id + "rowsPerPage")) || _rowsPerPage
  );
  const [sortDirection, setDirection] = useState<ASortDirection>(
    (localStorage.getItem(id + "sortDirection") || "Asc") as ASortDirection
  );
  const [sortColumn, setSortColumn] = useState(localStorage.getItem(id + "sortColumn") || "");

  const rowsPerPageOptions = [10, 25, 50, 100, 150, 200, 250];

  // Update localStorage whenever state values change
  useMemo(() => {
    localStorage.setItem(id + "query", text);
    localStorage.setItem(id + "page", String(page));
    localStorage.setItem(id + "rowsPerPage", String(rowsPerPage));
    localStorage.setItem(id + "sortDirection", sortDirection);
    localStorage.setItem(id + "sortColumn", sortColumn);
  }, [text, page, rowsPerPage, sortDirection, sortColumn]);

  const onPageChange = () => {
    setPage(0);
  };

  const onRowsPerPageChange = (e: any) => {
    setPage(0);
    setRowsPerPage(e.target.value);
  };

  const onSubmit = () => {
    setPage(0);
    setOnAction();
  };

  const { isLoading, response, execute } = useLazyAxios(url);
  const setOnAction = async () => {
    await execute(undefined, {
      query: text,
      sortColumn: sortColumn as any,
      pageSize: rowsPerPage,
      pageIndex: page,
      sortDirection: sortDirection,
    });
  };

  useMemo(() => {
    setOnAction();
  }, [page, rowsPerPage, sortColumn, sortDirection]);

  const count = useMemo(
    () => (response && "count" in response ? response.count || 0 : 0),
    [response]
  );
  const items = useMemo(
    () => (response && "items" in response ? response.items || [] : []),
    [response]
  );
  // const count = response && "count" in response ? response?.count || 0 : 0;
  // const items = response && "items" in response ? response?.items || [] : [];

  return {
    onSubmit,
    count,
    items,
    setOnAction,
    onRowsPerPageChange,
    onPageChange,
    text,
    setText,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    sortDirection,
    setDirection,
    sortColumn,
    setSortColumn,
    isLoading,
    rowsPerPageOptions,
  } as TableProps<(typeof items)[0]>;
};
