import React from "react";
import { Title } from "./title";
import {
  Box,
  Button,
  Card,
  Container,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import {
  ArrowDownOnSquareIcon,
  ArrowLeftIcon,
  ArrowUpOnSquareIcon,
  PlusIcon,
} from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { SearchBox } from "./search-box";

interface ISPage {
  children?: React.JSX.Element | React.ReactNode | React.JSX.Element[] | React.ReactNode[];
  title: string;
  pageTitle?: string;
  searchOptions?: {
    enabled?: boolean;
    onSubmit?: () => void;
    placeholder?: string;
    setText?: (t: string) => void;
    text?: string;
  };
  createOptions?: {
    enable?: boolean;
    url?: string;
    onClick?: () => void;
    title?: string;
  };
  importOptions?: {
    title?: string;
    enabled?: boolean;
    onClick?: () => void;
  };
  exportOptions?: {
    title?: string;
    enabled?: boolean;
    onClick?: () => void;
  };
}
export const SPage = ({
  children,
  title,
  pageTitle,
  createOptions,
  searchOptions,
  exportOptions,
  importOptions,
}: ISPage) => {
  const navigate = useNavigate();
  const isIEEnabled = importOptions?.enabled || exportOptions?.enabled;
  const isTitleEnabled = title || pageTitle;
  const isInnerEnabled = isIEEnabled || isTitleEnabled || createOptions?.enable;
  // const isRootEnabled = isIEEnabled || isTitleEnabled || isInnerEnabled;
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        pt: 2,
        pb: 8,
      }}
    >
      <Title title={title} />
      <Container maxWidth="xl">
        <Stack spacing={3}>
          {isInnerEnabled && (
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              {(isIEEnabled || isTitleEnabled) && (
                <Stack spacing={1}>
                  <Typography variant="h4">{pageTitle || title}</Typography>
                  {(importOptions?.enabled || exportOptions?.enabled) && (
                    <Stack alignItems="center" direction="row" spacing={1}>
                      {importOptions?.enabled && (
                        <Button
                          onClick={importOptions.onClick}
                          color="inherit"
                          startIcon={
                            <SvgIcon fontSize="small">
                              <ArrowUpOnSquareIcon />
                            </SvgIcon>
                          }
                        >
                          {importOptions.title || "Import"}
                        </Button>
                      )}
                      {exportOptions?.enabled && (
                        <Button
                          onClick={exportOptions.onClick}
                          color="inherit"
                          startIcon={
                            <SvgIcon fontSize="small">
                              <ArrowDownOnSquareIcon />
                            </SvgIcon>
                          }
                        >
                          {exportOptions.title || "Export"}
                        </Button>
                      )}
                    </Stack>
                  )}
                </Stack>
              )}
              {createOptions?.enable && (
                <div>
                  <Button
                    onClick={() => {
                      if (createOptions.onClick) {
                        createOptions.onClick();
                      } else if (createOptions.url) {
                        navigate(createOptions.url);
                      } else {
                        navigate("create");
                      }
                    }}
                    startIcon={
                      <SvgIcon fontSize="small">
                        <PlusIcon />
                      </SvgIcon>
                    }
                    variant="contained"
                  >
                    {createOptions.title || "Add New"}
                  </Button>
                </div>
              )}
            </Stack>
          )}
          {searchOptions?.enabled && (
            <Card sx={{ p: 2, display: "flex", alignItems: "center" }}>
              <SearchBox
                label={searchOptions.placeholder || "Search"}
                onSubmit={searchOptions.onSubmit}
                setText={searchOptions.setText}
                text={searchOptions.text}
              />
            </Card>
          )}
          {children}
        </Stack>
      </Container>
    </Box>
  );
};

interface ICreateProps extends ISPage {
  isUpdate: boolean;
}
export const CreateLayout = (props: ICreateProps) => {
  const navigate = useNavigate();
  return (
    <SPage {...props} title="" pageTitle="">
      <Title title={props.title} />
      <Stack direction={"row"} spacing={1}>
        <IconButton onClick={() => navigate(-1)}>
          <SvgIcon>
            <ArrowLeftIcon />
          </SvgIcon>
        </IconButton>
        <Typography variant="h4">
          {props.isUpdate ? "Update" : "Create"} {props.pageTitle || props.title}
        </Typography>
      </Stack>
      {props.children}
    </SPage>
  );
};
