import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useLazyAxios } from "src/hooks/useAxios";
import { Scrollbar } from "../../components/scrollbar";
import { IModel } from "./types";

export const ListTable: React.FC<TableProps<IModel>> = ({
  setOnAction,
  count,
  items,
  onPageChange,
  onRowsPerPageChange,
  page,
  rowsPerPage,
  setDirection,
  setSortColumn,
  sortColumn,
  sortDirection,
}) => {
  const navigate = useNavigate();

  return (
    <Card>
      <Scrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                {heads.map((s) => (
                  <TableCell
                    sortDirection={
                      sortColumn === s.id ? (sortDirection === "Asc" ? "asc" : "desc") : false
                    }
                    key={s.id}
                  >
                    {s.sortable ? (
                      <TableSortLabel
                        onClick={(e) => {
                          const isAsc = sortColumn === s.id && sortDirection === "Asc";
                          setDirection(isAsc ? "Desc" : "Asc");
                          setSortColumn(s.id);
                        }}
                        active={s.id === sortColumn}
                        direction={sortDirection === "Asc" ? "asc" : "desc"}
                      >
                        {s.title}
                      </TableSortLabel>
                    ) : (
                      s.title
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((row, i) => (
                <TableRow hover key={row.id}>
                  <TableCell>{i + 1 + rowsPerPage * page}</TableCell>
                  <TableCell>{}</TableCell>
                  <TableCell>{}</TableCell>
                  <TableCell>{}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50]}
      />
    </Card>
  );
};

const heads = [
  {
    id: "id",
    title: "#",
    sortable: false,
  },
  {
    id: "date",
    title: "Date",
    sortable: false,
  },
  {
    id: "email",
    title: "Customer email",
    sortable: false,
  },
  {
    id: "isCancelled",
    title: "Cancelled",
    sortable: false,
  },
];
