import { configureStore } from '@reduxjs/toolkit';
import AccountSlice from './account-slice';
import rootSlice from './root-slice';

export const AppStore = configureStore<AppStore>({
    reducer: {
        root: rootSlice,
        account: AccountSlice
    },
});
