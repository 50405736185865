import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { CreateLayout } from "src/components/spage";
import { useLazyAxios } from "src/hooks/useAxios";
import { z } from "zod";
import { IModel } from "./types";

interface IProps {}
export const ModuleCodesForm = ({}: IProps) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const isUpdate = !!id;

  const {
    getValues,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<IModel>({
    defaultValues: {
      name: "",
      competence1: "",
      competence2: "",
      competence3: "",
    },
    resolver: zodResolver(
      z.object({
        name: z.string().min(2, "Must be 2 digit").max(2, "Must be 2 digit"),
        competence1: z.string().nullable().optional(),
        competence2: z.string().nullable().optional(),
        competence3: z.string().nullable().optional(),
      })
    ),
  });

  const { isLoading: creating, execute: createCode } = useLazyAxios("api/Codes/Create");
  const { isLoading: updating, execute: updateCode } = useLazyAxios("api/Codes/Update");
  const { isLoading: getting, execute: FwsGet } = useLazyAxios("api/Codes/Get");

  const isLoading = creating || updating || getting;

  useEffect(() => {
    if (id && !id.includes("-0000")) {
      (async () => {
        const response = await FwsGet({ id });
        if (response && response.response && response.raw.status === 200) {
          reset(response.response.data);
        } else {
          toast.error(response?.response?.message || "No record found", { duration: 5_000 });
        }
      })();
    }
  }, [id]);

  const onSubmit = async (values: IModel) => {
    console.log(values);
    if (isLoading) return;
    const data: IModel = {
      ...values,
      id: id,
    };

    const response = await (isUpdate ? updateCode : createCode)(undefined, data);
    console.log(response);
    if (!!response?.response && response.raw.status === 200 && response.response.success) {
      toast.success("Saved successfully");
      navigate(-1);
    } else if (response?.raw.status === 400) {
      toast.error("Some values are missing but required.");
    } else {
      toast.error("Something went wrong. Please try again!");
    }
  };

  return (
    <CreateLayout
      isUpdate={isUpdate}
      title={(isUpdate ? "Update " : "Create ") + " Module codes"}
      pageTitle={"Module codes"}
    >
      <form onSubmit={handleSubmit(onSubmit, console.log)}>
        <Card style={{ marginTop: 15 }}>
          <CardHeader title="Basic Info" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={!!errors.name?.message}
                  fullWidth
                  label="Name"
                  helperText={errors.name?.message}
                  {...register("name")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={!!errors.competence1?.message}
                  fullWidth
                  label="Competence 1"
                  helperText={errors.competence1?.message}
                  {...register("competence1")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={!!errors.competence2?.message}
                  fullWidth
                  label="Competence 2"
                  helperText={errors.competence1?.message}
                  {...register("competence2")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={!!errors.competence3?.message}
                  fullWidth
                  label="Competence 3"
                  helperText={errors.competence1?.message}
                  {...register("competence3")}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button disabled={isLoading} type="submit" variant="contained" size="medium">
              Save
            </Button>
          </CardActions>
        </Card>
      </form>
    </CreateLayout>
  );
};
