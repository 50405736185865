import { SPage } from "src/components/spage";
import { ListTable } from "./table";
import { usePaginator } from "src/hooks/use-paginator";
import { URLs } from "src/hooks/types";
import { useEffect } from "react";
import { PoolType } from "./types";

interface IProps {
  type: PoolType;
  from: Roles;
}
export const PoolsPage = ({ from, type }: IProps) => {
  const url: URLs = type === "FWS" ? "api/FwsPools/List" : "api/TwsPools/List";
  const { ...props } = usePaginator(`__${type}_${from}__`, url);
  useEffect(() => {
    props.setOnAction();
  }, [type]);
  return (
    <SPage
      createOptions={{
        enable: type === "FWS" ? from === "LG" : type === "TWS" ? from === "LN" : false,
      }}
      searchOptions={{ enabled: true }}
      title={type + " Pools"}
    >
      <ListTable from={from} {...props} type={type} />
    </SPage>
  );
};
