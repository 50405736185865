import ArrowLeftIcon from "@heroicons/react/24/solid/ArrowLeftIcon";
import { Box, Button, Container, SvgIcon, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Title } from "src/components/title";

interface IProps {
  error: 401 | 404 | 500;
}
export const ErrorPage = ({ error }: IProps) => {
  const navigate = useNavigate();
  return (
    <>
      <Title
        title={
          error === 401
            ? "Access denied"
            : error === 404
            ? "404 Page not found"
            : "Internal server error"
        }
      />
      <Box
        component="main"
        sx={{
          mb: 4,
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          minHeight: "100%",
        }}
      >
        <Container maxWidth="md">
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                mb: 3,
                textAlign: "center",
              }}
            >
              <img
                alt="Under development"
                src={`/assets/errors/error-${error}.png`}
                style={{
                  display: "inline-block",
                  maxWidth: "100%",
                  width: 340,
                }}
              />
            </Box>
            <Typography align="center" sx={{ mb: 3 }} variant="h3">
              {error === 401
                ? "401: unauthorized or access is denied to the resource."
                : error === 404
                ? "404: The page you are looking for isn't here."
                : "500: Internal server error."}
            </Typography>
            <Typography align="center" color="text.secondary" variant="body1">
              {error === 401
                ? "You are unauthorized or your access to the resource is denied. consider going to login and use different credentials"
                : error === 404
                ? "You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation"
                : "Internal server error occured please try again later."}
            </Typography>
            {error === 401 ? (
              <Button
                onClick={(e) => navigate("/auth/login", { replace: true })}
                startIcon={
                  <SvgIcon fontSize="small">
                    <ArrowLeftIcon />
                  </SvgIcon>
                }
                sx={{ mt: 3 }}
                variant="contained"
              >
                Go to login
              </Button>
            ) : (
              <Button
                onClick={(e) => navigate("/", { replace: true })}
                startIcon={
                  <SvgIcon fontSize="small">
                    <ArrowLeftIcon />
                  </SvgIcon>
                }
                sx={{ mt: 3 }}
                variant="contained"
              >
                Go back to dashboard
              </Button>
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};
