import { useCallback, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  TextField,
} from "@mui/material";
import { useLazyAxios } from "src/hooks/useAxios";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import zod from "zod";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setIsAuthenticated } from "src/store/account-slice";
import { useNavigate } from "react-router-dom";

export const SettingsPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      newPassword: "",
      oldPassword: "",
    },
    resolver: zodResolver(
      zod.object({
        newPassword: zod
          .string({
            required_error: "Required",
          })
          .min(1, "Required"),
        oldPassword: zod
          .string({
            required_error: "Required",
          })
          .min(1, "Required"),
      })
    ),
  });

  const { isLoading, execute } = useLazyAxios("manage/info");

  const onSubmit = async (v) => {
    const response = await execute(undefined, {
      ...v,
    });
    if (!!response?.response && response.raw.status === 200 && response.response.success) {
      toast.success("Your password is updated.");
      dispatch(setIsAuthenticated(false));
      navigate("/auth/login", { replace: true });
    } else if (response?.raw.status === 400) {
      toast.error("Some values are missing but required.");
    } else {
      toast.error("Something went wrong. Please try again!");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardHeader subheader="Update password" title="Password" />
        <Divider />
        <CardContent>
          <Stack spacing={3} sx={{ maxWidth: 400 }}>
            <TextField
              error={!!errors.oldPassword?.message}
              fullWidth
              label="Old Password"
              type="password"
              helperText={errors.oldPassword?.message}
              {...register("oldPassword")}
            />
            <TextField
              error={!!errors.newPassword?.message!!}
              fullWidth
              label="Password (Confirm)"
              helperText={errors.newPassword?.message}
              {...register("newPassword")}
              type="password"
            />
          </Stack>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button disabled={isLoading} type="submit" variant="contained">
            Update
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};
