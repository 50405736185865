import { Box, Button, Link, Stack, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { Link as NLink, useNavigate } from "react-router-dom";
import { useLazyAxios } from "src/hooks/useAxios";
import * as z from "zod";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setIsAuthenticated, setProfile } from "src/store/account-slice";
import { useEffect } from "react";
import { setIsRendered } from "src/store/root-slice";
import { zodResolver } from "@hookform/resolvers/zod";

export const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isError, isLoading, execute } = useLazyAxios("forgotPassword");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      email: "w_licensor@sp.com",
    },
    resolver: zodResolver(
      z.object({
        email: z.string().email("Must be a valid email").max(255).min(1, "Email is required"),
      })
    ),
  });

  const onSubmit = async (values: any) => {
    const result = await execute(undefined, values);
    if (result?.raw.status === 200) {
      toast.success("Please check your email.");
      reset({ email: "" });
    } else {
      toast.error("Something went wrong.");
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        flex: "1 1 auto",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          maxWidth: 550,
          px: 3,
          py: "100px",
          width: "100%",
        }}
      >
        <div>
          <Stack spacing={1} sx={{ mb: 3 }}>
            <Typography variant="h4">Forgot Password</Typography>

            <Typography color="text.secondary" variant="body2">
              Know your password? &nbsp;
              <Link component={NLink} to="/auth/login" underline="hover" variant="subtitle2">
                Login here
              </Link>
            </Typography>
          </Stack>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <TextField
                error={!!errors.email?.message}
                fullWidth
                helperText={errors.email?.message}
                label="Email Address"
                {...register("email")}
                type="email"
              />
            </Stack>
            <Button
              disabled={isLoading}
              fullWidth
              size="large"
              sx={{ mt: 3 }}
              type="submit"
              variant="contained"
            >
              Submit
            </Button>
          </form>
        </div>
      </Box>
    </Box>
  );
};
