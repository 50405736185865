import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNProgress } from "src/hooks/use-nprogress";
import { createTheme } from "src/theme";
import { Router } from "./Router";
import { SplashScreen } from "./SplashScreen";

const App = () => {
  const {
    account: { isAuthenticated },
    root: { isRendered },
  } = useSelector((state: AppStore) => state);
  useNProgress();
  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toaster gutter={1} position="top-center" />
      {!isRendered ? <SplashScreen /> : <Router />}
    </ThemeProvider>
  );
};

export default App;
