import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useMemo } from "react";
import {
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormRegisterReturn,
  UseFormReturn,
  useForm,
} from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { MuiPhone } from "src/components/phone-input";
import { CreateLayout } from "src/components/spage";
import { URLs } from "src/hooks/types";
import { useLazyAxios } from "src/hooks/useAxios";
import { CustomerModel } from "./types";
import { z } from "zod";
import { IOSSwitch } from "src/components/IOSSwitch";

interface IProps {
  currentPage: RolePages;
}
export const CustomerForm = ({ currentPage = "EZB" }: IProps) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const isUpdate = !!id;

  const ops = useMemo<{ createUrl: URLs; updateUrl: URLs; getUrl: URLs; title: string }>(() => {
    if (currentPage === "EZB") {
      return {
        createUrl: "api/Customer/Create",
        updateUrl: "api/Customer/Update",
        getUrl: "api/Customer/Get",
        title: "Customer",
      };
    } else if (currentPage === "LN") {
      return {
        createUrl: "api/Licensee/Create",
        updateUrl: "api/Licensee/Update",
        getUrl: "api/Licensee/Get",
        title: "Licensee",
      };
    } else if (currentPage === "Admin") {
      return {
        createUrl: "api/Admin/Create",
        updateUrl: "api/Admin/Update",
        getUrl: "api/Admin/Get",
        title: "Sub Admin",
      };
    } else {
      return {
        createUrl: "api/Instructor/Create",
        updateUrl: "api/Instructor/Update",
        getUrl: "api/Instructor/Get",
        title: "Trainer",
      };
    }
  }, [currentPage]);

  const { isLoading: isCreating, execute: createCustomer } = useLazyAxios(ops.createUrl);
  const { isLoading: isUpdating, execute: updateCustomer } = useLazyAxios(ops.updateUrl);
  const { isLoading: isLoadingData, execute: loadData } = useLazyAxios(ops.getUrl);

  const isLoading = isCreating || isUpdating || isLoadingData;

  const require = z.string().min(1, "Required");
  const optional = z.string().nullable();
  const bankSchema = currentPage === "LN" ? require : optional;
  const {
    handleSubmit,
    getValues,
    control,
    setValue,
    register,
    reset,
    formState: { errors },
  } = useForm<CustomerModel>({
    reValidateMode: "onChange",
    mode: "all",
    defaultValues: {
      id: "",
      firstName: "",
      lastName: "",
      gender: "",
      phoneNumber: "",
      email: "",
      address: "",
      bankDetails: {
        accountHolderName: "",
        bankName: "",
        bic: "",
        iban: "",
      },
    },
    resolver: zodResolver(
      z.object({
        id: id ? require : z.string().optional().nullable(),
        firstName: optional,
        lastName: optional,
        gender: optional,
        phoneNumber: optional,
        email: z.string().email("Enter valid email").min(1, "Required"),
        address: optional,
        bankDetails: z.object({
          accountHolderName: bankSchema,
          bankName: bankSchema,
          iban: bankSchema,
          bic: bankSchema,
        }),
      })
    ),
  });

  useEffect(() => {
    if (id && !id.includes("-0000")) {
      (async () => {
        const response = await loadData({ id: id });
        if (response?.response && response.response.success) {
          const data = response.response.data;
          reset(data);
        } else {
          toast.error("Record not found.");
        }
      })();
    }
  }, [id]);

  const onSubmit = async (v: CustomerModel) => {
    const response = await (v.id ? updateCustomer : createCustomer)(undefined, v);
    if (!!response?.response && response.raw.status === 200 && response.response.success) {
      toast.success("Saved successfully");
      navigate(-1);
    } else if (response?.raw.status === 400) {
      toast.error("Some values are missing but required.");
    } else {
      toast.error("Something went wrong. Please try again!");
    }
  };
  return (
    <CreateLayout isUpdate={isUpdate} title={ops.title}>
      <form onSubmit={handleSubmit(onSubmit, console.log)}>
        <Card style={{ marginTop: 15, marginBottom: 15 }}>
          <CardHeader title="Basic Info" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={!!errors.firstName?.message}
                  fullWidth
                  label="First Name"
                  helperText={errors.firstName?.message}
                  {...register("firstName")}
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={!!errors.lastName?.message}
                  fullWidth
                  label="Last Name"
                  type="text"
                  helperText={errors.lastName?.message}
                  {...register("lastName")}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel>Gender</InputLabel>
                  <Controller
                    control={control}
                    name="gender"
                    render={({
                      field: { name, onBlur, onChange, ref, value, disabled },
                      fieldState: { error },
                    }) => (
                      <Select
                        error={!!error?.message}
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        ref={ref}
                        value={value}
                        disabled={disabled}
                      >
                        <MenuItem value="N">Select Gender</MenuItem>
                        <MenuItem value={"M"}>Männlich</MenuItem>
                        <MenuItem value={"F"}>Weiblich</MenuItem>
                        <MenuItem value={"O"}>Andere</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  sx={{ mt: 1.5 }}
                  control={
                    <Controller
                      control={control}
                      name="published"
                      render={({ field: { onChange, value } }) => (
                        <IOSSwitch
                          sx={{ ml: 1 }}
                          onChange={(e) => onChange(e.target.checked)}
                          checked={value}
                        />
                      )}
                    />
                  }
                  label={"Active: "}
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {currentPage === "LN" && <BankDetails errors={errors} register={register} />}
        <Card style={{ marginBottom: 15 }}>
          <CardHeader title="Contact Info" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={!!errors.email?.message}
                  fullWidth
                  label="Email"
                  type="email"
                  helperText={errors.email?.message}
                  {...register("email")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MuiPhone
                  fullWidth
                  error={!!errors.phoneNumber?.message}
                  helperText={errors.phoneNumber?.message}
                  value={getValues("phoneNumber") || ""}
                  onChange={(e) => setValue("phoneNumber", e)}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  multiline
                  rows={3}
                  fullWidth
                  error={!!errors.address?.message}
                  helperText={errors.address?.message}
                  label="Address"
                  {...register("address")}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button disabled={isLoading} type="submit" variant="contained" size="medium">
              Save
            </Button>
          </CardActions>
        </Card>
      </form>
    </CreateLayout>
  );
};
const BankDetails = ({
  register,
  errors,
}: {
  register: UseFormRegister<CustomerModel>;
  errors: FieldErrors<CustomerModel>;
}) => {
  return (
    <Card style={{ marginTop: 15, marginBottom: 15 }}>
      <CardHeader title="Bank Details" />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              error={!!errors.bankDetails?.bankName?.message}
              fullWidth
              label="Bank Name"
              helperText={errors.bankDetails?.bankName?.message}
              {...register("bankDetails.bankName")}
              type="text"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              error={!!errors.bankDetails?.accountHolderName?.message}
              fullWidth
              label="Account Holder Name"
              type="text"
              helperText={errors.bankDetails?.accountHolderName?.message}
              {...register("bankDetails.accountHolderName")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              error={!!errors.bankDetails?.iban?.message}
              fullWidth
              label="IBAN"
              helperText={errors.bankDetails?.iban?.message}
              {...register("bankDetails.iban")}
              type="text"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              error={!!errors.bankDetails?.bic?.message}
              fullWidth
              label="BIC"
              type="text"
              helperText={errors.bankDetails?.bic?.message}
              {...register("bankDetails.bic")}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
