import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Unstable_Grid2 as Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { MuiPhone } from "src/components/phone-input";
import { useLazyAxios } from "src/hooks/useAxios";
import { z } from "zod";

export const AccountProfileDetails = () => {
  const {
    getValues,
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm<MyProfile>({
    defaultValues: {
      firstName: "",
      lastName: "",
      gender: "",
      phoneNumber: "",
      email: "",
      address: "",
    },
    resolver: zodResolver(
      z.object({
        firstName: z.string().nullable().optional(),
        lastName: z.string().nullable().optional(),
        gender: z.string().nullable().optional(),
        email: z.string().nullable().optional(),
        phoneNumber: z.string().nullable().optional(),
        address: z.string().nullable().optional(),
      })
    ),
  });

  const { isLoading: updating, execute: updateProfile } = useLazyAxios("api/Account/UpdateProfile");
  const { isLoading: getting, execute: getProfile } = useLazyAxios("api/Account/MyProfile");

  const isLoading = updating || getting;

  useEffect(() => {
    (async () => {
      const response = await getProfile();
      if (!response) {
        toast.error("Seems like internet is not connected.");
      } else if (response && response.response && response.raw.status === 200) {
        reset(response.response.data);
      } else {
        toast.error(response?.response?.message || "No record found", { duration: 5_000 });
      }
    })();
  }, []);

  const onSubmit = async (values: MyProfile) => {
    console.log(values);
    if (isLoading) return;
    const data: MyProfile = {
      ...values,
    };

    const response = await updateProfile(undefined, data);
    console.log(response);
    if (!!response?.response && response.raw.status === 200 && response.response.success) {
      toast.success("Profile updated");
    } else if (response?.raw.status === 400) {
      toast.error("Some values are missing but required.");
    } else {
      toast.error("Something went wrong. Please try again!");
    }
  };
  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardHeader subheader="The information can be edited" title="Profile" />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid container spacing={3}>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  error={!!errors.firstName?.message}
                  helperText={errors.firstName?.message}
                  {...register("firstName")}
                  label="First name"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Last name"
                  error={!!errors.lastName?.message}
                  helperText={errors.lastName?.message}
                  {...register("lastName")}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <Controller
                  control={control}
                  name="phoneNumber"
                  render={({ field }) => (
                    <MuiPhone
                      fullWidth
                      label="Phone Number"
                      error={!!errors.phoneNumber?.message}
                      helperText={errors.phoneNumber?.message}
                      value={field.value || ""}
                      onChange={(e) => field.onChange(e)}
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel>Gender</InputLabel>
                  <Controller
                    control={control}
                    name="gender"
                    render={({ field, fieldState: { error } }) => (
                      <Select error={!!error?.message} {...field}>
                        <MenuItem value="N">
                          <em>Select Gender</em>
                        </MenuItem>
                        <MenuItem value={"M"}>Männlich</MenuItem>
                        <MenuItem value={"F"}>Weiblich</MenuItem>
                        <MenuItem value={"O"}>Andere</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} sm={12}>
                <TextField
                  multiline
                  rows={3}
                  fullWidth
                  error={!!errors.address?.message}
                  helperText={errors.address?.message}
                  label="Address"
                  {...register("address")}
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button variant="contained" type="submit" disabled={isLoading}>
            Save details
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};
