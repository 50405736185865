import { CustomerModel, UserList } from "src/pages/customers/types";
import { IPoolModel } from "src/pages/pools/types";
import { IModel as IQuestionModel } from "src/pages/questionnaire/types";

export type ReturnTypes = {
  "": undefined;
  login: TokenResponse | { success: false };
  forgotPassword: { token2: string };
  resetPassword: {};

  "api/Account/MyProfile": ApiResponse<MyProfile>;
  "api/Account/UpdateProfile": ApiResponse;
  "manage/info": ApiResponse;

  "api/Customer/Get": ApiResponse<CustomerModel>;
  "api/Customer/Create": ApiResponse;
  "api/Customer/Update": ApiResponse;
  "api/Customer/Delete": ApiResponse;
  "api/Customer/List": ListResponse<CustomerModel>;

  "api/Licensee/Get": ApiResponse<any>;
  "api/Licensee/Create": ApiResponse;
  "api/Licensee/Update": ApiResponse;
  "api/Licensee/Delete": ApiResponse;
  "api/Licensee/List": ListResponse<any>;

  "api/Instructor/Get": ApiResponse<any>;
  "api/Instructor/Create": ApiResponse;
  "api/Instructor/Update": ApiResponse;
  "api/Instructor/Delete": ApiResponse;
  "api/Instructor/List": ListResponse<any>;

  "api/Admin/Get": ApiResponse<any>;
  "api/Admin/Create": ApiResponse;
  "api/Admin/Update": ApiResponse;
  "api/Admin/Delete": ApiResponse;
  "api/Admin/List": ListResponse<any>;

  "api/FwsPools/Get": ApiResponse<any>;
  "api/FwsPools/Create": ApiResponse;
  "api/FwsPools/Update": ApiResponse;
  "api/FwsPools/Delete": ApiResponse;
  "api/FwsPools/List": ListResponse<any>;

  "api/TwsPools/Get": ApiResponse<any>;
  "api/TwsPools/Create": ApiResponse;
  "api/TwsPools/Update": ApiResponse;
  "api/TwsPools/Delete": ApiResponse;
  "api/TwsPools/List": ListResponse<any>;

  "api/Codes/Create": ApiResponse;
  "api/Codes/Update": ApiResponse;
  "api/Codes/Get": ApiResponse<any>;
  "api/Codes/List": ListResponse<any>;
  "api/Codes/Delete": ApiResponse;

  "api/Modules/Create": ApiResponse;
  "api/Modules/Update": ApiResponse;
  "api/Modules/Get": ApiResponse<any>;
  "api/Modules/List": ListResponse<any>;
  "api/Modules/Delete": ApiResponse;

  "api/Courses/Create": ApiResponse;
  "api/Courses/Update": ApiResponse;
  "api/Courses/Get": ApiResponse<any>;
  "api/Courses/List": ListResponse<any>;
  "api/Courses/Delete": ApiResponse;

  "api/Question/Create": ApiResponse;
  "api/Question/Update": ApiResponse;
  "api/Question/Get": ApiResponse<any>;
  "api/Question/List": ListResponse<IQuestionModel>;
  "api/Question/Delete": ApiResponse;

  "api/Options/ModuleCodes": OptionsListModel[];
  "api/Options/Modules": OptionsListModel[];
  "api/Options/Licensees": OptionsListModel[];

  "api/Orders/ListOrders": ApiResponse;
  "api/Orders/MyOrders": ApiResponse;
  "api/Orders/MyBookings": ApiResponse;
};

export type ParamTypes = {
  "": undefined;
  login: undefined;
  forgotPassword: { token2: string };
  resetPassword: undefined;

  "api/Account/MyProfile": undefined;
  "api/Account/UpdateProfile": undefined;
  "manage/info": undefined;

  "api/Customer/Create": undefined;
  "api/Customer/Update": undefined;
  "api/Customer/Get": { id: string };
  "api/Customer/List": undefined;
  "api/Customer/Delete": { id: string };

  "api/Licensee/Create": undefined;
  "api/Licensee/Update": undefined;
  "api/Licensee/Get": { id: string };
  "api/Licensee/List": undefined;
  "api/Licensee/Delete": { id: string };

  "api/Instructor/Create": undefined;
  "api/Instructor/Update": undefined;
  "api/Instructor/Get": { id: string };
  "api/Instructor/List": undefined;
  "api/Instructor/Delete": { id: string };

  "api/Admin/Create": undefined;
  "api/Admin/Update": undefined;
  "api/Admin/Get": { id: string };
  "api/Admin/List": undefined;
  "api/Admin/Delete": { id: string };

  "api/FwsPools/Create": undefined;
  "api/FwsPools/Update": undefined;
  "api/FwsPools/Get": { id: string };
  "api/FwsPools/List": undefined;
  "api/FwsPools/Delete": { id: string };

  "api/TwsPools/Create": undefined;
  "api/TwsPools/Update": undefined;
  "api/TwsPools/Get": { id: string };
  "api/TwsPools/List": undefined;
  "api/TwsPools/Delete": { id: string };

  "api/Codes/Create": undefined;
  "api/Codes/Update": undefined;
  "api/Codes/Get": { id: string };
  "api/Codes/List": undefined;
  "api/Codes/Delete": { id: string };

  "api/Modules/Create": undefined;
  "api/Modules/Update": undefined;
  "api/Modules/Get": { id: string };
  "api/Modules/List": undefined;
  "api/Modules/Delete": { id: string };

  "api/Courses/Create": undefined;
  "api/Courses/Update": undefined;
  "api/Courses/Get": { id: string };
  "api/Courses/List": undefined;
  "api/Courses/Delete": { id: string };

  "api/Question/Create": undefined;
  "api/Question/Update": undefined;
  "api/Question/Get": { id: string };
  "api/Question/List": undefined;
  "api/Question/Delete": { id: string };

  "api/Options/ModuleCodes": undefined;
  "api/Options/Modules": undefined;
  "api/Options/Licensees": undefined;

  "api/Orders/ListOrders": undefined;
  "api/Orders/MyOrders": undefined;
  "api/Orders/MyBookings": undefined;
};
export type BodyTypes = {
  "": undefined;
  login: {
    username: string;
    password: string;
  };
  forgotPassword: { token2: string };
  resetPassword: {
    email: string;
    resetCode: string;
    newPassword: string;
  };

  "api/Account/MyProfile": undefined;
  "api/Account/UpdateProfile": MyProfile;
  "manage/info": {
    newUsername?: string;
    newEmail?: string;
    newPassword?: string;
    oldPassword?: string;
  };

  "api/Customer/Create": CustomerModel;
  "api/Customer/Update": CustomerModel;
  "api/Customer/Get": undefined;
  "api/Customer/List": PaginationModel<any>;
  "api/Customer/Delete": undefined;

  "api/Licensee/Create": CustomerModel;
  "api/Licensee/Update": CustomerModel;
  "api/Licensee/Get": undefined;
  "api/Licensee/List": PaginationModel<any>;
  "api/Licensee/Delete": undefined;

  "api/Instructor/Create": CustomerModel;
  "api/Instructor/Update": CustomerModel;
  "api/Instructor/Get": undefined;
  "api/Instructor/List": PaginationModel<any>;
  "api/Instructor/Delete": undefined;

  "api/Admin/Create": CustomerModel;
  "api/Admin/Update": CustomerModel;
  "api/Admin/Get": undefined;
  "api/Admin/List": PaginationModel<any>;
  "api/Admin/Delete": undefined;

  "api/FwsPools/Create": any;
  "api/FwsPools/Update": any;
  "api/FwsPools/Get": undefined;
  "api/FwsPools/List": PaginationModel<IPoolModel>;
  "api/FwsPools/Delete": undefined;

  "api/TwsPools/Create": any;
  "api/TwsPools/Update": any;
  "api/TwsPools/Get": undefined;
  "api/TwsPools/List": PaginationModel<IPoolModel>;
  "api/TwsPools/Delete": undefined;

  "api/Codes/Create": any;
  "api/Codes/Update": any;
  "api/Codes/Get": undefined;
  "api/Codes/List": PaginationModel<IPoolModel>;
  "api/Codes/Delete": undefined;

  "api/Modules/Create": any;
  "api/Modules/Update": any;
  "api/Modules/Get": undefined;
  "api/Modules/List": PaginationModel<IPoolModel>;
  "api/Modules/Delete": undefined;

  "api/Courses/Create": any;
  "api/Courses/Update": any;
  "api/Courses/Get": undefined;
  "api/Courses/List": PaginationModel<IPoolModel>;
  "api/Courses/Delete": undefined;

  "api/Question/Create": any;
  "api/Question/Update": any;
  "api/Question/Get": undefined;
  "api/Question/List": PaginationModel<IQuestionModel>;
  "api/Question/Delete": undefined;

  "api/Options/ModuleCodes": undefined;
  "api/Options/Modules": undefined;
  "api/Options/Licensees": undefined;

  "api/Orders/ListOrders": undefined;
  "api/Orders/MyOrders": undefined;
  "api/Orders/MyBookings": undefined;
};
export type URLs = keyof ReturnTypes & keyof ParamTypes & keyof BodyTypes;
export type Methods = { [x in URLs]: "POST" | "GET" | "PUT" | "DELETE" | "PATCH" };
export const methods: Methods = {
  "": "GET",
  login: "POST",
  forgotPassword: "POST",
  resetPassword: "POST",

  "api/Account/MyProfile": "GET",
  "api/Account/UpdateProfile": "POST",
  "manage/info": "POST",

  "api/Customer/Create": "POST",
  "api/Customer/Update": "PUT",
  "api/Customer/Get": "GET",
  "api/Customer/List": "POST",
  "api/Customer/Delete": "DELETE",

  "api/Licensee/Create": "POST",
  "api/Licensee/Update": "PUT",
  "api/Licensee/Get": "GET",
  "api/Licensee/List": "POST",
  "api/Licensee/Delete": "DELETE",

  "api/Instructor/Create": "POST",
  "api/Instructor/Update": "PUT",
  "api/Instructor/Get": "GET",
  "api/Instructor/List": "POST",
  "api/Instructor/Delete": "DELETE",

  "api/Admin/Create": "POST",
  "api/Admin/Update": "PUT",
  "api/Admin/Get": "GET",
  "api/Admin/List": "POST",
  "api/Admin/Delete": "DELETE",

  "api/FwsPools/Create": "POST",
  "api/FwsPools/Update": "PUT",
  "api/FwsPools/Get": "GET",
  "api/FwsPools/List": "POST",
  "api/FwsPools/Delete": "DELETE",

  "api/TwsPools/Create": "POST",
  "api/TwsPools/Update": "PUT",
  "api/TwsPools/Get": "GET",
  "api/TwsPools/List": "POST",
  "api/TwsPools/Delete": "DELETE",

  "api/Codes/Create": "POST",
  "api/Codes/Update": "PUT",
  "api/Codes/Get": "GET",
  "api/Codes/List": "POST",
  "api/Codes/Delete": "DELETE",

  "api/Modules/Create": "POST",
  "api/Modules/Update": "PUT",
  "api/Modules/Get": "GET",
  "api/Modules/List": "POST",
  "api/Modules/Delete": "DELETE",

  "api/Courses/Create": "POST",
  "api/Courses/Update": "PUT",
  "api/Courses/Get": "GET",
  "api/Courses/List": "POST",
  "api/Courses/Delete": "DELETE",

  "api/Question/Create": "POST",
  "api/Question/Update": "PUT",
  "api/Question/Get": "GET",
  "api/Question/List": "POST",
  "api/Question/Delete": "DELETE",

  "api/Options/ModuleCodes": "GET",
  "api/Options/Modules": "GET",
  "api/Options/Licensees": "GET",

  "api/Orders/ListOrders": "GET",
  "api/Orders/MyOrders": "GET",
  "api/Orders/MyBookings": "GET",
};
export const getDefaultParams = (key: URLs) => {
  switch (key) {
    case "login":
      return { cookieMode: false, persistCookies: false };

    default:
      return undefined;
  }
};

export interface PaginationModel<SortColumns extends any> {
  query?: string;
  pageSize?: number;
  pageIndex?: number;
  sortColumn?: SortColumns;
  sortDirection: "Asc" | "Desc";
}
