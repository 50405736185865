import { SPage } from "src/components/spage";
import { usePaginator } from "src/hooks/use-paginator";
import { CustomersTable } from "src/pages/customers/customers-table";
export const CustomersPage = ({ from }: { from: Roles }) => {
  const pagination = usePaginator("__customers__", "api/Customer/List");

  return (
    <SPage
      title="Customers"
      searchOptions={{
        enabled: true,
        ...pagination,
      }}
      createOptions={{ enable: false }}
    >
      <CustomersTable {...pagination} currentPage="EZB" from={from} />
    </SPage>
  );
};
