import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { de } from "date-fns/locale/de";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "react-confirm-alert/src/react-confirm-alert.css";
import "simplebar-react/dist/simplebar.min.css";
import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { Provider } from "react-redux";
import { AppStore } from "./store";
import { FirebaseOptions, initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { LocalizationProvider } from "@mui/x-date-pickers";

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyATcyuvOC6fjqtYer9lakYWaedmikHH83Q",
  authDomain: "swimpool-89b03.firebaseapp.com",
  projectId: "swimpool-89b03",
  storageBucket: "swimpool-89b03.appspot.com",
  messagingSenderId: "2592681282",
  appId: "1:2592681282:web:323298866325f0d5d92cdb",
  measurementId: "G-K47EX4YF10",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter window={window} basename="/">
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
        <Provider store={AppStore}>
          <App />
        </Provider>
      </LocalizationProvider>
    </BrowserRouter>
  </React.StrictMode>
);
