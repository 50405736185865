import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FilledInput,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { CreateLayout } from "src/components/spage";
import { useLazyAxios } from "src/hooks/useAxios";
import { IModel } from "./types";
import { IOSSwitch } from "src/components/IOSSwitch";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

interface IProps {}
export const CourseModuleForm = ({}: IProps) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    getValues,
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm<IModel>({
    defaultValues: {
      name: "",
      description: "",
      basePrice: 0,
      downPaymentPrice: 0,
      moduleIds: [],
      published: true,
    },
    resolver: zodResolver(schema),
  });
  const isUpdate = !!id;

  const { isLoading: isCreating, execute: createCourse } = useLazyAxios("api/Courses/Create");
  const { isLoading: isUpdating, execute: updateCourse } = useLazyAxios("api/Courses/Update");

  const { isLoading: isGetting, execute: getCourse } = useLazyAxios("api/Courses/Get");
  const { isLoading: isListingModules, execute: listModules } = useLazyAxios("api/Options/Modules");

  const isLoading = isCreating || isUpdating || isGetting || isListingModules;

  const [modules, setModules] = useState<OptionsListModel[]>([]);

  useEffect(() => {
    if (id && !id.includes("-0000")) {
      (async () => {
        const response = await getCourse({ id });
        if (response && response.response && response.raw.status === 200) {
          reset(response.response.data);
        } else {
          toast.error(response?.response?.message || "No record found", { duration: 5_000 });
        }
      })();
    }
    (async () => {
      const resp = await listModules();
      setModules(resp?.response);
    })();
  }, [id]);
  const onSubmit = async (values: IModel) => {
    console.log(values);
    if (isLoading) return;
    const data: IModel = {
      ...values,
      id: id,
    };

    const response = await (isUpdate ? updateCourse : createCourse)(undefined, data);
    console.log(response);
    if (!!response?.response && response.raw.status === 200 && response.response.success) {
      toast.success("Saved successfully");
      navigate(-1);
    } else if (response?.raw.status === 200) {
      toast.error("Some values are missing but required.");
    } else {
      toast.error("Something went wrong. Please try again!");
    }
  };
  return (
    <CreateLayout
      isUpdate={isUpdate}
      title={(isUpdate ? "Update " : "Create ") + " Course module"}
      pageTitle={"Course module"}
    >
      <form onSubmit={handleSubmit(onSubmit, console.log)}>
        <Card style={{ marginTop: 15 }}>
          <CardHeader title="Basic Info" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={!!errors.name?.message}
                  fullWidth
                  label="Name"
                  helperText={errors.name?.message}
                  {...register("name")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  sx={{ mt: 1.5 }}
                  control={
                    <Controller
                      control={control}
                      name="published"
                      render={({ field: { onChange, value } }) => (
                        <IOSSwitch
                          sx={{ ml: 1 }}
                          onChange={(e) => onChange(e.target.checked)}
                          checked={value}
                        />
                      )}
                    />
                  }
                  label={"Active: "}
                  labelPlacement="start"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={!!errors.basePrice?.message}
                  fullWidth
                  type="number"
                  label="Base Price"
                  helperText={errors.basePrice?.message}
                  {...register("basePrice", { valueAsNumber: true })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={!!errors.downPaymentPrice?.message}
                  fullWidth
                  type="number"
                  label="Down Payment Price"
                  helperText={errors.downPaymentPrice?.message}
                  {...register("downPaymentPrice", { valueAsNumber: true })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="filled" error={!!errors.moduleIds?.message} fullWidth>
                  <InputLabel id="m_ids">Modules</InputLabel>
                  <Controller
                    control={control}
                    name="moduleIds"
                    render={({ field }) => (
                      <Select labelId="m_ids" multiple {...field}>
                        {modules.map((s) => (
                          <MenuItem key={s.value} value={s.value}>
                            {s.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errors.moduleIds?.message && (
                    <FormHelperText>{errors.moduleIds?.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  multiline
                  rows={3}
                  error={!!errors.description?.message}
                  fullWidth
                  label="Description"
                  helperText={errors.description?.message}
                  {...register("description")}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button disabled={isLoading} type="submit" variant="contained" size="medium">
              Save
            </Button>
          </CardActions>
        </Card>
      </form>
    </CreateLayout>
  );
};
const required_message = "Required";
const greater_message = "Must be greater than 0";
const schema = z.object({
  id: z.string().optional().nullable(),
  name: z.string().min(1, required_message),
  description: z.string(),
  basePrice: z.number().min(1, greater_message),
  downPaymentPrice: z.number().min(1, greater_message),
  moduleIds: z.array(z.string().min(1, required_message)),
  published: z.boolean(),
});
