import { SPage } from "src/components/spage";
import { ListTable } from "./table";
import { usePaginator } from "src/hooks/use-paginator";

interface IProps {}
export const ModuleCoursePage = ({}: IProps) => {
  const pagination = usePaginator("__module_courses__", "api/Courses/List");
  return (
    <SPage
      createOptions={{ enable: true }}
      searchOptions={{ enabled: true, ...pagination }}
      title={"Module Courses"}
    >
      <ListTable {...pagination} />
    </SPage>
  );
};
