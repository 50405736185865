import { Box, Container, Stack, Typography } from "@mui/material";
import { SettingsNotifications } from "./settings-notifications";
import { SettingsPassword } from "./settings-password";
import { Title } from "src/components/title";

export const SettingsPage = () => (
  <>
    <Title title="Settings" />
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        pt: 2,
        pb: 8,
      }}
    >
      <Container maxWidth="xl">
        <Stack spacing={3}>
          <Typography variant="h4">Settings</Typography>
          {/* <SettingsNotifications /> */}
          <SettingsPassword />
        </Stack>
      </Container>
    </Box>
  </>
);
