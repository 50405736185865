import {
  Box,
  Card,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  CircularProgress,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Scrollbar } from "../../components/scrollbar";
import { IPoolModel, PoolType } from "./types";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import { confirmAlertUI } from "src/components/alertUI";
import { useLazyAxios } from "src/hooks/useAxios";
import toast from "react-hot-toast";

interface IProps extends TableProps<IPoolModel> {
  from: Roles;
  type: PoolType;
}
export const ListTable = ({
  setOnAction,
  items,
  setDirection,
  setSortColumn,
  sortColumn,
  sortDirection,
  from,
  type,
  ...props
}: IProps) => {
  const [rows, setRows] = useState(items);
  useMemo(() => {
    setRows(items);
  }, [items]);
  return (
    <Card>
      <Scrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                {heads
                  .filter((s) => s.for === from || s.for === undefined)
                  .filter((s) => s.type === type || s.type === undefined)
                  .map((s) => (
                    <TableCell
                      sortDirection={
                        sortColumn === s.id ? (sortDirection === "Asc" ? "asc" : "desc") : false
                      }
                      key={s.id}
                    >
                      {s.sortable ? (
                        <TableSortLabel
                          onClick={(e) => {
                            const isAsc = sortColumn === s.id && sortDirection === "Asc";
                            setDirection(isAsc ? "Desc" : "Asc");
                            setSortColumn(s.id);
                          }}
                          active={s.id === sortColumn}
                          direction={sortDirection === "Asc" ? "asc" : "desc"}
                        >
                          {s.title}
                        </TableSortLabel>
                      ) : (
                        s.title
                      )}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <Row
                  key={row.id}
                  i={i}
                  page={props.page}
                  row={row}
                  rowsPerPage={props.rowsPerPage}
                  type={type}
                  onRemove={() => {
                    const lr = [...rows];
                    const index = lr.indexOf(row);
                    lr.splice(index, 1);
                    setRows(lr);
                  }}
                  from={from}
                />
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={props.count}
        onPageChange={props.onPageChange}
        page={props.page}
        rowsPerPage={props.rowsPerPage}
        onRowsPerPageChange={props.onRowsPerPageChange}
        rowsPerPageOptions={props.rowsPerPageOptions}
      />
    </Card>
  );
};

const Row = ({
  row,
  i,
  page,
  rowsPerPage,
  type,
  onRemove,
  from,
}: {
  row: IPoolModel;
  i: number;
  rowsPerPage: number;
  page: number;
  type: PoolType;
  from: Roles;
  onRemove: () => void;
}) => {
  const navigate = useNavigate();
  const { execute, isLoading } = useLazyAxios(
    type === "FWS" ? "api/FwsPools/Delete" : "api/TwsPools/Delete"
  );
  const hasPermission = type === "FWS" ? from === "LG" : from === "LN";
  const onEdit = () => {
    if (!hasPermission) {
      toast.error(`You cannot edit ${type} pool`);
      return;
    }
    navigate("edit/" + row.id);
  };

  const onDelete = () => {
    if (!hasPermission) {
      toast.error(`You cannot delete ${type} pool`);
      return;
    }
    confirmAlertUI({
      onConfirm: async () => {
        await execute({ id: row.id! });
        onRemove();
      },
    });
  };
  return (
    <TableRow hover>
      <TableCell>{i + 1 + rowsPerPage * page}</TableCell>
      <TableCell>{row.name}</TableCell>
      <TableCell>{row.address || "N/A"}</TableCell>
      <TableCell>{row.contactPersonName}</TableCell>
      <TableCell>{row.email}</TableCell>
      <TableCell>{row.phone}</TableCell>
      {type === "TWS" && (
        <TableCell>
          {row.assignedPools.length > 0
            ? row.assignedPools.map((a, ind) => (
                <Typography key={a.lns + "_" + ind}>
                  Name: {a.lns}
                  Quota: {a.licenseQuota}
                </Typography>
              ))
            : "None"}
        </TableCell>
      )}
      <TableCell sx={{ display: "flex" }}>
        {isLoading ? (
          <CircularProgress size={20} />
        ) : (
          <>
            <IconButton color="primary" onClick={onEdit}>
              <SvgIcon fontSize="small">
                <PencilSquareIcon />
              </SvgIcon>
            </IconButton>

            <IconButton color="error" onClick={onDelete}>
              <SvgIcon fontSize="small">
                <TrashIcon />
              </SvgIcon>
            </IconButton>
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

const heads = [
  {
    id: "id",
    title: "#",
    sortable: false,
  },
  {
    id: "name",
    title: "Surname",
    sortable: true,
  },
  {
    id: "address",
    title: "Address",
    sortable: true,
  },
  {
    id: "contactPersonName",
    title: "Contact person name",
    sortable: true,
  },
  {
    id: "email",
    title: "Email",
    sortable: true,
  },
  {
    id: "phone",
    title: "Phone",
    sortable: true,
    for: undefined,
  },
  {
    id: "lns",
    title: "LNs",
    sortable: false,
    type: "TWS",
  },
  {
    id: "action",
    title: "Action",
    sortable: false,
    for: undefined,
  },
];
