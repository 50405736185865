import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import {
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IOSSwitch } from "src/components/IOSSwitch";
import { useLazyAxios } from "src/hooks/useAxios";
import { Scrollbar } from "../../components/scrollbar";
import { CustomerModel } from "./types";
import toast from "react-hot-toast";
import { StatusSwitch } from "src/components/status-switch";
import { URLs } from "src/hooks/types";
import { current } from "@reduxjs/toolkit";

interface IProps extends TableProps<CustomerModel> {
  currentPage?: RolePages;
  from: Roles;
}
export const CustomersTable = ({
  setOnAction,
  count,
  items,
  onPageChange,
  onRowsPerPageChange,
  page,
  rowsPerPage,
  setDirection,
  setSortColumn,
  sortColumn,
  sortDirection,
  rowsPerPageOptions,
  currentPage,
  from,
}: IProps) => {
  const navigate = useNavigate();
  const url: URLs = useMemo<URLs>(
    () =>
      currentPage === "EZB"
        ? "api/Customer/Delete"
        : currentPage === "LN"
        ? "api/Licensee/Delete"
        : currentPage === "SL"
        ? "api/Instructor/Delete"
        : currentPage === "Admin"
        ? "api/Admin/Delete"
        : "",
    [currentPage]
  );
  const { execute: deleteCustomer } = useLazyAxios(url);

  const [showConfirm, setShowConfirm] = useState(false);
  const [id, setId] = useState<string>();
  const hasPermission = (to: "edit" | "delete") => {
    if (currentPage === "EZB") {
      toast.error(`You cannot ${to} ${currentPage}`);
      return false;
    } else if (currentPage === "SL" && from !== "LN") {
      toast.error(`You cannot ${to} ${currentPage}`);
      return false;
    }
    return true;
  };
  const onEdit = (row: CustomerModel) => {
    if (!hasPermission("edit")) return;
    navigate("edit/" + row.id);
  };
  const onDelete = (row: CustomerModel) => {
    if (!hasPermission("delete")) return;
    setId(row.id);
    setShowConfirm(true);
  };
  return (
    <Card>
      <Scrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                {heads(currentPage).map((s) => (
                  <TableCell
                    sortDirection={
                      sortColumn === s.id ? (sortDirection === "Asc" ? "asc" : "desc") : false
                    }
                    key={s.id}
                  >
                    {s.sortable ? (
                      <TableSortLabel
                        onClick={(e) => {
                          const isAsc = sortColumn === s.id && sortDirection === "Asc";
                          setDirection(isAsc ? "Desc" : "Asc");
                          setSortColumn(s.id);
                        }}
                        active={s.id === sortColumn}
                        direction={sortDirection === "Asc" ? "asc" : "desc"}
                      >
                        {s.title}
                      </TableSortLabel>
                    ) : (
                      s.title
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((row, i) => (
                <TableRow hover key={row.id}>
                  <TableCell>{i + 1 + rowsPerPage * page}</TableCell>
                  <TableCell>
                    <Stack alignItems="start" direction="row" spacing={1}>
                      <Typography variant="subtitle2">{row.name}</Typography>
                      {row.gender && <Chip color="primary" size="small" label={row.gender} />}
                    </Stack>
                  </TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.phoneNumber}</TableCell>
                  <TableCell>{row.address}</TableCell>
                  {currentPage === "EZB" && <TableCell>{row.noOfStudents}</TableCell>}
                  {currentPage === "SL" && (
                    <>
                      <TableCell>{row.ln}</TableCell>
                      <TableCell>{row.qualification}</TableCell>
                    </>
                  )}
                  {currentPage === "LN" && (
                    <>
                      <TableCell>{row.bankDetails?.bankName}</TableCell>
                      <TableCell>
                        <StatusSwitch
                          readonly
                          defaultValue={row.published ?? false}
                          url="login"
                          id={row.id ?? ""}
                        />
                      </TableCell>
                      <TableCell>{row.sls}</TableCell>
                    </>
                  )}
                  <TableCell>
                    <Stack direction={"row"}>
                      <IconButton color="primary" onClick={() => onEdit(row)}>
                        <SvgIcon fontSize="small">
                          <PencilSquareIcon />
                        </SvgIcon>
                      </IconButton>
                      <IconButton color="error" onClick={() => onDelete(row)}>
                        <SvgIcon fontSize="small">
                          <TrashIcon />
                        </SvgIcon>
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
      />
      <React.Fragment>
        <Dialog open={showConfirm} onClose={() => setShowConfirm(false)}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete customer?
              <br />
              This action is not reversable.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowConfirm(false)} autoFocus>
              No cancel
            </Button>
            <Button
              color="error"
              onClick={async () => {
                if (id) {
                  const response = await deleteCustomer({
                    id: id,
                  });
                  if (response?.response && response.response.success === true) {
                    setOnAction?.();
                  } else {
                    toast.error(response?.response.message);
                  }
                  setShowConfirm(false);
                  setId(undefined);
                }
              }}
            >
              Yes, delete
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </Card>
  );
};

const heads = (currentPage: RolePages = "EZB") => {
  let items = [
    {
      id: "id",
      title: "#",
      sortable: false,
    },
    {
      id: "firstName",
      title: "Name",
      sortable: true,
    },
    {
      id: "email",
      title: "Email",
      sortable: true,
    },
    {
      id: "phone",
      title: "Phone",
      sortable: true,
    },
    {
      id: "address",
      title: "Address",
      sortable: true,
    },
  ];
  if (currentPage === "EZB") {
    items.push({
      id: "nos",
      title: "Number of students",
      sortable: true,
    });
  } else if (currentPage === "SL") {
    items.push(
      {
        id: "ln",
        title: "LN",
        sortable: false,
      },
      {
        id: "qualification",
        title: "Qualification",
        sortable: false,
      }
    );
  } else if (currentPage === "LN") {
    items.push(
      {
        id: "bankDetails",
        title: "Bank details",
        sortable: false,
      },
      {
        id: "status",
        title: "Status",
        sortable: true,
      },
      {
        id: "sls",
        title: "SLs",
        sortable: false,
      }
    );
  }

  items.push({
    id: "action",
    title: "Actions",
    sortable: false,
  });
  return items;
};
