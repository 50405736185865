import { createSlice } from '@reduxjs/toolkit';

const initialState: RootOptions = {
    isRendered: false
};

export const AccountSlice = createSlice({
    name: 'Root',
    initialState,
    reducers: {
        setIsRendered: (state, action: IAction<boolean>) => {
            state.isRendered = action.payload;
        },
    },
});

export const {
    setIsRendered
} = AccountSlice.actions

export default AccountSlice.reducer;
