import { SPage } from "src/components/spage";
import { URLs } from "src/hooks/types";
import { usePaginator } from "src/hooks/use-paginator";
import { CustomersTable } from "../customers/customers-table";

interface IProps {
  from: Roles;
}
export const LicenseePage = ({ from }: IProps) => {
  const { ...props } = usePaginator(`__licensees__`, "api/Licensee/List");
  return (
    <SPage
      createOptions={{ enable: true }}
      searchOptions={{
        enabled: true,
        ...props,
      }}
      title={"Licensee (LN)"}
      pageTitle="Licensee"
    >
      <CustomersTable {...props} currentPage="LN" from={from} />
    </SPage>
  );
};
