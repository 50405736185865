import { Control, Controller, FieldErrors, UseFormRegister, useFieldArray } from "react-hook-form";
import { IPoolModel } from "./types";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  SvgIcon,
  TextField,
} from "@mui/material";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useLazyAxios } from "src/hooks/useAxios";
import { useEffect, useMemo } from "react";

export const FwsRepeater = ({
  control,
  register,
  errors,
  action,
  list,
}: {
  control: Control<IPoolModel, any>;
  register: UseFormRegister<IPoolModel>;
  errors: FieldErrors<IPoolModel>;
  action: JSX.Element;
  list: OptionsListModel[];
}) => {
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: "assignedPools",
  });
  const AppendButton = (
    <IconButton
      onClick={() => {
        append({ id: "", licenseeId: "", licenseQuota: 1 });
      }}
    >
      <SvgIcon>
        <PlusIcon />
      </SvgIcon>
    </IconButton>
  );

  return (
    <Card style={{ marginTop: 15 }}>
      <CardHeader title="Assigned LNs" />
      <Divider />
      <CardContent>
        {fields.map((s, i) => (
          <Grid sx={{ mb: 2 }} container spacing={3} key={s.id}>
            <Grid item md={6} sm={12} xs={12}>
              <FormControl
                variant="filled"
                fullWidth
                error={!!errors.assignedPools?.[i]?.licenseeId?.message}
              >
                <InputLabel>LN Name</InputLabel>
                <Controller
                  control={control}
                  name={`assignedPools.${i}.licenseeId`}
                  render={({ field }) => (
                    <Select {...field}>
                      {list.map((s, i) => (
                        <MenuItem key={s.value} value={s.value}>
                          {s.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.assignedPools?.[i]?.licenseeId?.message && (
                  <FormHelperText>{errors.assignedPools?.[i]?.licenseeId?.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={5} sm={12} xs={12}>
              <TextField
                fullWidth
                type="number"
                error={!!errors.assignedPools?.[i]?.licenseQuota?.message}
                helperText={errors.assignedPools?.[i]?.licenseQuota?.message}
                label="License Quota"
                {...register(`assignedPools.${i}.licenseQuota` as const, {
                  valueAsNumber: true,
                })}
              />
            </Grid>
            <Grid item md={1} sm={12} xs={12}>
              <Stack direction={"row"}>
                <IconButton onClick={() => remove(i)} color="error">
                  <SvgIcon>
                    <XMarkIcon />
                  </SvgIcon>
                </IconButton>
                {i === fields.length - 1 && AppendButton}
              </Stack>
            </Grid>
          </Grid>
        ))}
        {fields.length === 0 && (
          <CardActions sx={{ justifyContent: "flex-end" }}>{AppendButton}</CardActions>
        )}
      </CardContent>
      <Divider />
      {action}
    </Card>
  );
};
