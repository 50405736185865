import { useState } from 'react';

import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import ReactCountryFlag from 'react-country-flag';

const LANGS: { value: string; label: string; icon: any; }[] = [
    // {
    //     value: 'en',
    //     label: 'English',
    //     icon: <ReactCountryFlag countryCode='US' svg />
    // },
    {
        value: 'de',
        label: 'German',
        icon: <ReactCountryFlag countryCode='DE' svg />
    }
];

export default function LanguagePopover() {
    const [open, setOpen] = useState<any>(null);

    const handleClose = () => {
        setOpen(null);
    };

    return (
        <>
            <IconButton
                onClick={(event) => {
                    setOpen(event.currentTarget);
                }}
                sx={{
                    width: 40,
                    height: 40,
                    ...(open && {
                        bgcolor: 'action.selected',
                    }),
                }}
            >
                {LANGS[0].icon}
            </IconButton>

            <Popover
                open={!!open}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                slotProps={{
                    paper: {
                        sx: {
                            p: 0,
                            mt: 1,
                            ml: 0.75,
                            width: 180,
                        },
                    }
                }}
            >
                {LANGS.map((option) => (
                    <MenuItem
                        key={option.value}
                        selected={option.value === LANGS[0].value}
                        onClick={() => handleClose()}
                        sx={{ typography: 'body2', py: 1 }}
                    >
                        {/* <Box component="img" alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} /> */}
                        <span style={{
                            width: 28,
                            marginRight: 2,
                        }}>{option.icon}</span>
                        {option.label}
                    </MenuItem>
                ))}
            </Popover>
        </>
    );
}