import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import { Card, IconButton, InputAdornment, OutlinedInput, SvgIcon } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import { useCallback, useEffect, useState } from "react";

export const SearchBox = (props: {
  label: string;
  text?: string;
  setText?: (t: string) => void;
  onSubmit?: () => void;
}) => {
  const [t, setT] = useState("");
  useEffect(() => {
    if (t != props.text && !props.text) {
      props.onSubmit?.();
    } else {
      setT(props.text);
    }
  }, [props.text]);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        props.onSubmit?.();
      }}
    >
      {/* <Card sx={{ p: 2 }}> */}
      <OutlinedInput
        value={props.text}
        onChange={(e) => props.setText?.(e.target.value)}
        fullWidth
        placeholder={props.label}
        startAdornment={
          <InputAdornment position="start">
            <SvgIcon color="action" fontSize="small">
              <MagnifyingGlassIcon />
            </SvgIcon>
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            {props.text && (
              <IconButton
                onClick={() => {
                  props.setText?.("");
                }}
              >
                <SvgIcon color="action" fontSize="small">
                  {/* Replace with your clear icon */}
                  <ClearIcon />
                </SvgIcon>
              </IconButton>
            )}
            <IconButton onClick={() => props.onSubmit?.()}>
              <SvgIcon color="action" fontSize="small">
                <MagnifyingGlassIcon />
              </SvgIcon>
            </IconButton>
          </InputAdornment>
        }
        // sx={{ maxWidth: 500 }}
      />
      {/* </Card> */}
    </form>
  );
};
