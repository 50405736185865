import { SPage } from "src/components/spage";
import { CustomersTable } from "../customers/customers-table";
import { usePaginator } from "src/hooks/use-paginator";

interface IProps {
  from: Roles;
}
export const TrainersPage = ({ from }: IProps) => {
  const pagination = usePaginator("__instructor__", "api/Instructor/List");
  return (
    <SPage
      createOptions={{ enable: from === "LN" }}
      searchOptions={{
        enabled: true,
        ...pagination,
      }}
      title={"Swimming Trainers"}
    >
      <CustomersTable {...pagination} currentPage="SL" from={from} />
    </SPage>
  );
};
